import React, { useEffect, useMemo, useState } from "react";
import { Cell, Grid } from "baseui/layout-grid";
import { cellPaddingOverrides, gridPaddingOverrides } from "../overrides";
import { useStyletron } from "baseui";
import Button from "../styledButton";
import AddNewDefaultTemplateModal from "../modals/add-new-default-template-modal";
import { useLazyQuery, useMutation } from "@apollo/client";
import { sharepoint_form_templates, template_tags_integration_group_by_tag, template_tags_org } from "../templates/queries";
import { Accordion, Panel } from "baseui/accordion";
import { client } from "../../apollo/client";
import { HeadingXSmall, LabelMedium, ParagraphMedium } from "baseui/typography";
import { Modal, ModalBody, ModalButton, ModalFooter, ModalHeader } from "baseui/modal";
import { Input } from "baseui/input";
import { createDefaultTemplateGroup, default_template_groups, deleteDefaultTemplateGroup, updateDefaultTemplateGroup } from "./queries";
import { toaster } from "baseui/toast";
import styled from '@emotion/styled';
import * as FontAwesome from 'react-icons/fa';
import { Spinner } from "baseui/spinner";

const BtnImageSvg = styled.svg`
  position: relative;
  
  cursor: pointer;
`;

const BtnImageContainer = styled.div`
  padding: 6px;
`

const FormActionButtonContainer = styled.div`
  display: flex;
  position: relative;
  width: 100%;

`;

const DefaultTemplatesList = [{
  id: '12',
  name: 'First Group',
  templates_list: []
}]



const Automation = ({ organization, ...props }) => {
  const [css, theme] = useStyletron();
  const [tags, setTags] = useState([]);
  const [loading, setLoading] = useState(false);
  const [selectedTemplates, setSelectedTemplates] = useState([]);
  const [isAddModalOpen, setIsAddModalOpen] = useState(false);
  const [isAddDetailModalOpen, setIsAddDetailModalOpen] = useState(false);
  const [isEditDetailModalOpen, setIsEditDetailModalOpen] = useState(false);
  const [title, setTitle] = useState('');
  const [defaultTemplateGroupsList, setDefaultTemplateGroupsList] = useState([]);
  const [templateTagIntegrationData, setTemplateTagIntegrationData] = useState([]);
  const [selectedTemplateGroup, setSelectedTemplateGroup] = useState();
  const [isWarningModalOpen, setIsWarningModalOpen] = React.useState(false);
  const [isDefaultTemplateGroupDeleting, setIsDefaultTemplateGroupDeleting] = React.useState(false);


  const [fetchTemplateTagIntegration, { data: templateTagIntegration, loading: templateTagIntegrationLoading, refetch: refetchTemplateTagInteg }] = useLazyQuery(template_tags_integration_group_by_tag,
    {
      variables: {
        organization_id: organization.id
      },
      fetchPolicy: 'network-only'
    }
  );
  const [fetchFormTemplates, { data: formTemplates, loading: formTemplatesLoading, refetch: refetchFormTemplates }] = useLazyQuery(sharepoint_form_templates,
    {
      variables: {
        organization_id: organization.id
      },
      fetchPolicy: 'network-only'
    }
  );

  const [fetchDefaultTemplateGroups, { data: default_template_groups_data, loading: defaultTemplateGroupsLoading, refetch: refetchDefaultTemplateGroups }] = useLazyQuery(default_template_groups,
    {
      variables: {
        organization_id: organization.id
      },
      fetchPolicy: 'network-only'
    }
  );

  const [
    createDefaultTemplateGroupMutation,
    { data: createdDefaultTemplateGroup, loading: isCreatingDefaultTemplateGroup },
  ] = useMutation(createDefaultTemplateGroup);

  const [
    updateDefaultTemplateGroupMutation,
    { data: updatedDefaultTemplateGroup, loading: isUpdatingDefaultTemplateGroup },
  ] = useMutation(updateDefaultTemplateGroup);

  const [
    deleteDefaultTemplateGroupMutation,
  ] = useMutation(deleteDefaultTemplateGroup);

  const loadTags = () => {
    setLoading(true);
    client.query({
      query: template_tags_org(organization.id),
      fetchPolicy: 'network-only'
    }).then(({ data }) => {

      setTags([...data.template_tags_org].sort((a, b) => a.position - b.position));
    }).catch((err) => {
      console.log('loadTags error ', err)
    }).finally(() => {
      setLoading(false);
    })
  };

  useEffect(() => {
    loadTags();
    fetchDefaultTemplateGroups();
    fetchTemplateTagIntegration();
    fetchFormTemplates();
  }, [])

  useEffect(() => {
    // console.log('defaultTemplateGroupsList', default_template_groups_data)
    setDefaultTemplateGroupsList(default_template_groups_data?.default_template_groups || [])
  }, [default_template_groups_data])

  useEffect(() => {
    const {
      template_tags_integration_group_by_tag: templateTagIntegrations = []
    } = templateTagIntegration || {};

    setTemplateTagIntegrationData(templateTagIntegrations || []);
  }, [templateTagIntegration])

  const showToaster = (msg, method = 'info') => {
    let toastKey = toaster[method](
      <>
        {msg}
      </>,
      {
        overrides: {
          InnerContainer: {
            style: { width: "100%" }
          }
        }
      }
    );
    setTimeout(() => { toaster.clear(toastKey) }, 3000);
  };

  const onAddTemplatePress = () => {
    setIsAddModalOpen(true)
  }
  const onCloseAddModal = () => {
    setIsAddModalOpen(false)
  }

  const onAddTemplateDetailPress = () => {
    setIsAddDetailModalOpen(true)
  }

  const onCloseAddDetailModal = () => {
    setIsAddDetailModalOpen(false);
    setTitle('');
    setSelectedTemplates([]);
  }

  const onCloseEditDetailModal = () => {
    setIsEditDetailModalOpen(false);
    setTitle('');
    setSelectedTemplates([]);
    setSelectedTemplateGroup();
  }

  const onSelectTemplates = async (templatesTags) => {
    try {
      const mutationFunc = isEditDetailModalOpen ? onUpdateTemplateGroupClick : onAddTemplateGroupClick;
      const createdTemplateGroup = await mutationFunc(templatesTags);
      setIsAddDetailModalOpen(false);
      setIsAddModalOpen(false);
      showToaster(`Default Template Group ${isEditDetailModalOpen ? 'Updated' : 'Added'} Successfully`, 'positive');
      onCloseAddDetailModal();
      onCloseEditDetailModal();
      refetchDefaultTemplateGroups();
      return createdTemplateGroup;
    } catch (err) {
      showToaster(err.message, 'negative');
      return Promise.reject(err)
    }
  }


  const onAddTemplateGroupClick = async (templatesTags) => {
    try {
      const payload = {
        "title": title,
        "description": "",
        "organization_id": organization.id,
        "templatesInput": templatesTags.map(el => {
          return ({
            "template_id": el.id,
            "template_type": "SharePointFormModule"
          })
        })
      }

      const created = await createDefaultTemplateGroupMutation({
        variables: payload
      })
      return created;
    } catch (err) {
      return Promise.reject(err)
    }
  }

  const onUpdateTemplateGroupClick = async (templatesTags) => {
    try {
      const payload = {
        id: selectedTemplateGroup?.id,
        "title": title,
        "description": "",
        "templatesInput": templatesTags.map(el => {
          return ({
            "template_id": el.id,
            "template_type": "SharePointFormModule"
          })
        })
      }

      const updated = await updateDefaultTemplateGroupMutation({
        variables: payload
      })
      return updated;
    } catch (err) {
      return Promise.reject(err)
    }
  }

  const onDeleteDefaultTemplate = async () => {
    try {
      setIsDefaultTemplateGroupDeleting(true);
      const deleteData = await deleteDefaultTemplateGroupMutation({
        variables: {
          id: selectedTemplateGroup.id
        }
      })

      const {
        data: {
          deleteDefaultTemplateGroup
        }
      } = deleteData

      // console.log('onDeleteDefaultTemplate ----->', deleteData)
      setIsDefaultTemplateGroupDeleting(false);
      if (!deleteDefaultTemplateGroup) {
        showToaster('Something Went Wrong! Please Try Again', 'positive');
        return;
      }
      showToaster('Default Template Group Deleted Successfully', 'positive');
      onCloseWarningModal();
      refetchDefaultTemplateGroups();
    } catch (err) {
      setIsDefaultTemplateGroupDeleting(false);
      showToaster(err.message, 'negative');
    }
  }

  const onCloseWarningModal = () => {
    setIsWarningModalOpen(false);
    setSelectedTemplateGroup();
  }

  ///////////////////////////////////////////////// Render Functions ////////////////////////////////////////////////

  const AddTemplateGroupDetailModal = useMemo(() => {
    return (
      <Modal
        overrides={{
          Backdrop: {
            style: ({ $theme }) => ({
              backdropFilter: $theme.modalBackDropFilter,
            }),
          }
        }}
        isOpen={isAddDetailModalOpen}
        onClose={onCloseAddDetailModal}
        autoFocus={false}
      >
        <ModalHeader>{'Add New Template Group'}</ModalHeader>
        <ModalBody style={{ flex: '1 1 0' }}>
          <Grid
            gridGaps={[12, 12, 24]}
          // overrides={{
          //   Grid: {
          //     style: ({ $theme }) => outerGridOverrid,
          //   },
          // }}
          >
            <Cell
              span={[1, 12]}
              overrides={{
                Cell: {
                  style: () => ({
                    paddingLeft: '0px !important',
                  }),
                },
              }}
            >
              <LabelMedium style={{ marginBottom: '16px' }}>Title</LabelMedium>
              <Input
                value={title}
                onChange={e => {
                  setTitle(e.target.value);
                }}
                placeholder="Default Template Group Name"
                clearOnEscape
              />

            </Cell>
          </Grid>
        </ModalBody>
        <ModalFooter>
          <ModalButton
            kind="tertiary"
            onClick={onCloseAddDetailModal}
          >
            Cancel
          </ModalButton>
          <ModalButton
            onClick={onAddTemplatePress}
          >
            Select Template Tags
          </ModalButton>
        </ModalFooter>
      </Modal>
    )
  }, [isAddDetailModalOpen, title])

  const EditTemplateGroupDetailModal = useMemo(() => {
    return (
      <Modal
        overrides={{
          Backdrop: {
            style: ({ $theme }) => ({
              backdropFilter: $theme.modalBackDropFilter,
            }),
          }
        }}
        isOpen={isEditDetailModalOpen}
        onClose={onCloseEditDetailModal}
        autoFocus={false}
      >
        <ModalHeader>{'Edit Template Group'}</ModalHeader>
        <ModalBody style={{ flex: '1 1 0' }}>
          <Grid
            gridGaps={[12, 12, 24]}
          >
            <Cell
              span={[1, 12]}
              overrides={{
                Cell: {
                  style: () => ({
                    paddingLeft: '0px !important',
                  }),
                },
              }}
            >
              <LabelMedium style={{ marginBottom: '16px' }}>Title</LabelMedium>
              <Input
                value={title}
                onChange={e => {
                  setTitle(e.target.value);
                }}
                placeholder="Default Template Group Name"
                clearOnEscape
              />

            </Cell>
          </Grid>
        </ModalBody>
        <ModalFooter>
          <ModalButton
            kind="tertiary"
            onClick={onCloseEditDetailModal}
          >
            Cancel
          </ModalButton>
          <ModalButton
            onClick={onAddTemplatePress}
          >
            Select Template Tags
          </ModalButton>
        </ModalFooter>
      </Modal>
    )
  }, [isEditDetailModalOpen, title])

  const renderWarningModal = () => {
    const onClickYes = () => {
      onDeleteDefaultTemplate();
    }

    return (
      <Modal
        overrides={{
          Root: {
            style: () => ({
              zIndex: 1
            })
          }
        }}
        onClose={onCloseWarningModal}
        autoFocus={false}
        isOpen={isWarningModalOpen}
      >
        <ModalHeader>Are you sure that you want to delete template group ?</ModalHeader>
        <ModalFooter>
          <ModalButton
            onClick={onClickYes}
            isLoading={isDefaultTemplateGroupDeleting}
          >
            Yes
          </ModalButton>
          <ModalButton
            onClick={onCloseWarningModal}
            isLoading={isDefaultTemplateGroupDeleting}
          >
            No
          </ModalButton>
        </ModalFooter>
      </Modal>
    )
  }


  const renderTemplateItem = (template, idx) => {
    const templateGroup = tags.find(el => el.id == template.template_id);
    const templatesInteg = (templateTagIntegrationData || []).find(el => el.tag_id == template.template_id);
    // console.log('templateGroup ----->', templates)
    return (
      <div>
        <div
          className={css({
            display: 'flex',
            paddingTop: '5px',
            paddingBottom: '5px',
            alignItems: 'center',
            width: '100%'

          })}
        >
          <div
            className={css({
              display: 'flex',
              alignItems: 'center',
              cursor: 'pointer',
              flex: 1
            })}
          >

            <ParagraphMedium
              className={css({
                textTransform: 'capitalize',
                fontFamily: 'Roboto',
                textOverflow: 'ellipsis',
                maxHeight: '24px',
                fontWeight: '600',
                overflow: 'hidden',
                whiteSpace: 'nowrap'
              })}
              marginBottom={0}
            >
              {templateGroup?.name || `Template ${idx}`}
            </ParagraphMedium>

          </div>

        </div>

        <div
          className={css({
            marginLeft: '10px',
            color: 'grey',
            marginBottom: '10px !important'
          })}
        >
          {(templatesInteg?.templates || []).map((el, idx) => {

            const template = formTemplates?.sharepoint_form_templates?.find(el1 => el1.id === el.template_id);
            // console.log('template ------_>', template, el.template_id)
            if (!template) {
              return ''
            }
            return `${template?.title || ''},`
          })}
        </div>


      </div>
    )
  }

  const renderFormOptionButtons = (item, index) => {
    const onClickEdit = (e) => {
      e.preventDefault();
      e.stopPropagation();
      setSelectedTemplateGroup(item);
      setTitle(item?.title);
      setIsEditDetailModalOpen(true)
    }
    const onClickDelete = (e) => {
      e.preventDefault();
      e.stopPropagation();
      setSelectedTemplateGroup(item);
      setIsWarningModalOpen(true)
    }


    const renderEditButton = () => {
      return (
        <BtnImageContainer
          onClick={onClickEdit}
          style={{
            marginLeft: '5px',
            marginRight: '5px',
            paddingLeft: '5px',
            paddingRight: '5px',
          }}
        >
          <FontAwesome.FaPen />
        </BtnImageContainer>
      )
    }
    const renderDeleteButton = () => {
      return (
        <BtnImageContainer
          onClick={onClickDelete}
          style={{
            marginLeft: '5px',
            marginRight: '5px',
            paddingLeft: '5px',
            paddingRight: '5px',
          }}
        >
          <BtnImageSvg
            width="14"
            height="18"
            viewBox="0 0 14 18"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M1 16C1 17.1 1.9 18 3 18H11C12.1 18 13 17.1 13 16V4H1V16ZM14 1H10.5L9.5 0H4.5L3.5 1H0V3H14V1Z"
              fill={'black'}
            />
          </BtnImageSvg>
        </BtnImageContainer>
      );
    }
    return (
      <div
        className='form_item_option_container'
      >
        <FormActionButtonContainer>
          {renderEditButton()}
          {renderDeleteButton()}
        </FormActionButtonContainer>
      </div>
    )
  }

  const DefaultTemplatesList = useMemo(() => {
    return (

      <Accordion
        accordion
        overrides={{
          Root: {
            style: ({ $theme }) => ({
              marginLeft: 0,
            })
          },
          PanelContainer: {
            style: ({ $theme }) => ({
              backgroundColor: 'white',
              marginBottom: '1rem'
            })
          }
        }}
      >
        {defaultTemplateGroupsList.map((group, index) => (
          <Panel
            title={
              <div
                style={{
                  width: '100%',
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center'

                }}

                id={'position_' + index}
                key={'position_' + index}
              >
                <HeadingXSmall marginBottom={0}>{`${group?.title || `group ${index}`}`}</HeadingXSmall>
                {renderFormOptionButtons(group, index)}
              </div>
            }

          >
            {(group?.templates || []).map(renderTemplateItem)}
          </Panel>
        ))}
      </Accordion>
    )

  }, [defaultTemplateGroupsList, tags, templateTagIntegration, formTemplates])


  const selectedTemplGroupTags = useMemo(() => {
    return (selectedTemplateGroup?.templates || []).map(template => {
      const templateTag = tags.find(el => el.id == template.template_id);
      return templateTag;
    })
  }, [selectedTemplateGroup, tags])

  return (
    <>
      <Grid
        overrides={gridPaddingOverrides}
      >
        <Cell
          overrides={cellPaddingOverrides}
          span={12}
        >
          <div
            className={css({
              float: 'right'
            })}
          >
            <Button
              onClick={onAddTemplateDetailPress}
              isSelected
            >Add New Template Group
            </Button>
          </div>
        </Cell>
        <div
          className={css({
            flex: 1,
            marginTop: '15px'
          })}
        >
          {
            (defaultTemplateGroupsLoading && loading) && (
              <div
                className={css({
                  display: 'flex',
                  width: '100%',
                  justifyContent: 'center',
                  alignContent: 'center',
                  paddingBottom: '10px'
                })}
              >
                <Spinner
                  size={40}
                  color='black'
                />
              </div>
            )
          }

          {
            (defaultTemplateGroupsLoading && loading && defaultTemplateGroupsList.length <= 0) && (
              <div
                className={css({
                  display: 'flex',
                  width: '100%',
                  justifyContent: 'center',
                  alignContent: 'center',
                  paddingBottom: '10px'
                })}
              >
                No Data Available
              </div>
            )
          }
          {DefaultTemplatesList}
        </div>
      </Grid>
      <AddNewDefaultTemplateModal
        isEdit={isEditDetailModalOpen}
        isVisible={isAddModalOpen}
        onClose={onCloseAddModal}
        organization={organization}
        onSelect={onSelectTemplates}
        selectedTemplateTags={selectedTemplGroupTags}
      />
      {AddTemplateGroupDetailModal}
      {EditTemplateGroupDetailModal}
      {renderWarningModal()}
    </>
  )
}

export default Automation;