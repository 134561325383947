import gql from 'graphql-tag';

export const updateLoginModuleSpecs = gql`
	mutation updateLoginModuleSpecs(
		$organization_id: ID
    $login_logo_data: String
		$login_logo: Upload
		$background_color: String
		$background_img: Upload
    $background_img_data: String
		$is_background_img: Boolean
		$label_txt_color: String
		$button_background_color: String
		$button_txt_color: String
	) {
		updateLoginModuleSpecs (
			organization_id: $organization_id
			login_logo: $login_logo
      login_logo_data: $login_logo_data
			background_color: $background_color
			background_img_data: $background_img_data
      background_img: $background_img
			is_background_img: $is_background_img
			label_txt_color: $label_txt_color
			button_background_color: $button_background_color
			button_txt_color: $button_txt_color  
		) {
        background_color
        label_txt_color
        button_txt_color
        button_background_color
        login_logo_data
        is_background_img
        background_img_data
    }
	}
`;

export const login_module_specification = (organization_id) => gql`
	query login_module_specification{
		login_module_specification(organization_id: "${organization_id}") {
		organization_id
		login_logo_data
		background_color
		background_img_data
		is_background_img
		label_txt_color
		button_background_color
		button_txt_color 
		} 
	}
`

export const pwa_design_specification = (organization_id) => gql`
	query pwa_design_specification{
		pwa_design_specification(organization_id: "${organization_id}"){
			id
		   navigation_button_color
		   search_button_color
		   navigation_bar_color
		   organization_id
		   pwa_app_id
		   created_at
		   updated_at
		} 
	}
`;

export const createPwaDesignSpecs = gql`
	mutation createPwaDesignSpecs(
		$organization_id: ID
		$pwa_app_id: ID
		$navigation_button_color: String
		$search_button_color: String
		$navigation_bar_color: String
	) {
		createPwaDesignSpecs(input: {
			navigation_button_color: $navigation_button_color
			search_button_color: $search_button_color
			navigation_bar_color: $navigation_bar_color
			organization_id: $organization_id
			pwa_app_id: $pwa_app_id
		}) {
			id
			navigation_button_color
			search_button_color
			navigation_bar_color
			organization_id
			pwa_app_id
			created_at
			updated_at
		}
	}
`;

export const updatePwaDesignSpecs = gql`
	mutation updatePwaDesignSpecs(
		$id: ID
		$organization_id: ID
		$pwa_app_id: ID
		$navigation_button_color: String
		$search_button_color: String
		$navigation_bar_color: String
	){
		updatePwaDesignSpecs(
			id: $id
			input : {
				navigation_button_color: $navigation_button_color
				search_button_color: $search_button_color
				navigation_bar_color: $navigation_bar_color
				organization_id: $organization_id
				pwa_app_id: $pwa_app_id 
			}
		) {
			id
			navigation_button_color
			search_button_color
			navigation_bar_color
			organization_id
			pwa_app_id
			created_at
			updated_at
		}
	}
`;

export const createMicrosoftIntegration = gql`
	mutation createMicrosoftIntegration (
	$name: String
	$client_id: String
	$tenant_id: String
	$client_secret: String
	$organization_id: String
	$access_token: String
	$refresh_token: String
	){
		createMicrosoftIntegration (
			name: $name
			client_id: $client_id
			tenant_id: $tenant_id
			client_secret: $client_secret
			organization_id: $organization_id
			access_token: $access_token
			refresh_token: $refresh_token
		){
		id
		name
		client_id
		tenant_id
		client_secret
		organization_id
		access_token
		refresh_token
		redirectUri
		}
	}
`;

export const updateMicrosoftIntegration = gql`
	mutation updateMicrosoftIntegration (
	$id: ID
	$name: String
	$client_id: String
	$tenant_id: String
	$client_secret: String
	){
		updateMicrosoftIntegration (
			id: $id
			name: $name
			client_id: $client_id
			tenant_id: $tenant_id
			client_secret: $client_secret
		){
		id
		name
		client_id
		tenant_id
		client_secret
		organization_id
		access_token
		refresh_token
		redirectUri
		}
	}
`;

export const microsoftIntegrations = gql`
	query microsoft_integrations($orgId: ID) {
		microsoft_integrations(orgId: $orgId) {
			id
			name
			client_id
			tenant_id
			client_secret
			organization_id
			access_token
			refresh_token
		}
	}
`;

export const updateMicrosoftIntegrationByRefreshToken = gql`
	mutation updateMicrosoftIntegrationByRefreshToken($id: ID) {
		updateMicrosoftIntegrationByRefreshToken(id: $id) {
			success
			microsoft_integration {
			id
			name
			client_id
			tenant_id
			client_secret
			organization_id
			access_token
			refresh_token
			}
		}
	}
`;

export const microsoft_integration = gql`
	query microsoft_integration($id: ID) {
		microsoft_integration(id: $id) {
			id
			name
			client_id
			tenant_id
			client_secret
			organization_id
			access_token
			refresh_token
		}
	}
`;

export const createHubspotIntegration = gql`
	mutation createHubspotIntegration (
	$name: String
	$client_id: String
	$client_secret: String
	$web_redirect_url: String
	$organization_id: String
	){
		createHubspotIntegration (
			name: $name
			client_id: $client_id
			web_redirect_url: $web_redirect_url
			client_secret: $client_secret
			organization_id: $organization_id
		){
			hubspot_integration {
				id
				name
				client_id
				client_secret
				organization_id
				access_token
				refresh_token
			}
			redirect_url
			authorization_url
		}
	}
`;

export const updateHubspotIntegration = gql`
	mutation updateHubspotIntegration (
		$id: ID!
		$name: String
		$client_id: String
		$client_secret: String
		$web_redirect_url: String
	){
	updateHubspotIntegration (
		id: $id
		name: $name
		client_id: $client_id
		client_secret: $client_secret
		web_redirect_url: $web_redirect_url
	){
		hubspot_integration {
		id
		name
		client_id
		client_secret
		organization_id
		access_token
		refresh_token
		}
		redirect_url
		authorization_url
	}
	}
`;

export const organization_share_point_sheets_org = gql`
	query organization_share_point_sheets_org ($organization_id: ID){
	organization_share_point_sheets_org(
		organization_id: $organization_id
	) {
		id
		name
		organization_id
		client_id
		client_secret
		tenant_id
		sheet_name
		sync_in_realtime
		sync_frequency
		is_ready
		spreadsheet_id
		username
		password
		organization_mongo_id
		data_template
		site_name
		site_id
		drive_id
		drive_name
		microsoft_integration_id
	}
	}
`

export const createDefaultTemplateGroup = gql`
	mutation createDefaultTemplateGroup (
		$title: String
		$description: String
		$organization_id: ID!
		$templatesInput: [DefaultTemplateGroupTemplatesInput]
	) {
		createDefaultTemplateGroup(
		title: $title
		description: $description
		organization_id: $organization_id
		templatesInput: $templatesInput
		) {
		id
		title
		organization_id
		}
	}
`;

export const updateDefaultTemplateGroup = gql`
	mutation updateDefaultTemplateGroup (
		$id: ID!
		$title: String
		$description: String
		$templatesInput: [DefaultTemplateGroupTemplatesInput]
	) {
		updateDefaultTemplateGroup(
		id: $id
		title: $title
		description: $description
		templatesInput: $templatesInput
		) {
		id
		title
		organization_id
		}
	}
`

export const default_template_groups = gql`
	query default_template_groups($organization_id: ID!){
		default_template_groups(organization_id: $organization_id){
		id
		title
		description
		templates {
			template_id
			template_type
		}
		}
	}
`
export const default_template_group = gql`
	query default_template_group($id: ID!){
		default_template_group(id: $id){
		id
		title
		description
		templates {
			template_id
			template_type
		}
		}
	}
`

export const deleteDefaultTemplateGroup = gql`
  mutation deleteDefaultTemplateGroup (
    $id: ID!
  ) {
    deleteDefaultTemplateGroup (
      id: $id
    )
  }
`
export const crunchbase_integration_by_org = gql`
query all_crunchbase_integration_by_org($organization_id:ID!) {
	all_crunchbase_integration_by_org(organization_id:$organization_id){
	  id
	  title
	}
  }
`
export const fetch_form_schema_from_crunchbase = gql`
query fetch_form_schema_from_crunchbase($integration_id:ID!) {
	fetch_form_schema_from_crunchbase(integration_id:$integration_id)
  }
`