import gql from 'graphql-tag';
export const APPENV_OPTIONS = {
  type: 'App Environments',
  display: 'App Environments',
  fields: [
    {
      key: 'APIPROTOCOL',
      display: 'APIPROTOCOL',
      isPasswordType: false,
      isBoolean: false
    },
    {
      key: 'APIHOST',
      display: 'APIHOST',
      isPasswordType: false,
      isBoolean: false
    },
    {
      key: 'APIPORT',
      display: 'APIPORT',
      isPasswordType: false,
      isBoolean: false
    },
    {
      key: 'ASSET_HOST',
      display: 'ASSET_HOST',
      isPasswordType: false,
      isBoolean: false
    },
    {
      key: 'API_GRAPHQL',
      display: 'API_GRAPHQL',
      isPasswordType: false,
      isBoolean: false
    },
    {
      key: 'API_PUSH_NOTIFICATION_HOST',
      display: 'API_PUSH_NOTIFICATION_HOST',
      isPasswordType: false,
      isBoolean: false
    },
    {
      key: 'ORG_NAME',
      display: 'ORG_NAME',
      isPasswordType: false,
      isBoolean: false
    },
    {
      key: 'ENV_NAME',
      display: 'ENV_NAME',
      isPasswordType: false,
      isBoolean: false
    },
    {
      key: 'ANDROID_YOUTUBE_API_KEY',
      display: 'ANDROID_YOUTUBE_API_KEY',
      isPasswordType: false,
      isBoolean: false
    },
    {
      key: 'IOS_YOUTUBE_API_KEY',
      display: 'IOS_YOUTUBE_API_KEY',
      isPasswordType: false,
      isBoolean: false
    },
    {
      key: 'INDEX_BG_COLOR',
      display: 'INDEX_BG_COLOR',
      isPasswordType: false,
      isBoolean: false
    },
    {
      key: 'PW_RESET_REDIRECT_URL',
      display: 'PW_RESET_REDIRECT_URL',
      isPasswordType: false,
      isBoolean: false
    },
    {
      key: 'MOBILE_TERMS_URL',
      display: 'MOBILE_TERMS_URL',
      isPasswordType: false,
      isBoolean: false
    },
    {
      key: 'MOBILE_TOUR_URL',
      display: 'MOBILE_TOUR_URL',
      isPasswordType: false,
      isBoolean: false
    },
    {
      key: 'SUPPORT_URL',
      display: 'SUPPORT_URL',
      isPasswordType: false,
      isBoolean: false
    },
    {
      key: 'SEGMENT_KEY',
      display: 'SEGMENT_KEY',
      isPasswordType: false,
      isBoolean: false
    },
    {
      key: 'AUTH0_CLIENT_ID',
      display: 'AUTH0_CLIENT_ID',
      isPasswordType: false,
      isBoolean: false
    },
    {
      key: 'AUTHO_DOMAIN',
      display: 'AUTHO_DOMAIN',
      isPasswordType: false,
      isBoolean: false
    },
    {
      key: 'HIDE_SHADOW',
      display: 'HIDE_SHADOW',
      isPasswordType: false,
      isBoolean: true
    },
    {
      key: 'IS_SINGLE_THEME',
      display: 'IS_SINGLE_THEME',
      isPasswordType: false,
      isBoolean: true
    },
    {
      key: 'VERTICAL_SLIDER',
      display: 'VERTICAL_SLIDER',
      isPasswordType: false,
      isBoolean: true
    },
    {
      key: 'LARGE_DECK',
      display: 'LARGE_DECK',
      isPasswordType: false,
      isBoolean: true
    },
    {
      key: 'HIDE_ARTICLES',
      display: 'HIDE_ARTICLES',
      isPasswordType: false,
      isBoolean: true
    },
    {
      key: 'HAS_PINCODE',
      display: 'HAS_PINCODE',
      isPasswordType: false,
      isBoolean: true
    },
    {
      key: 'HEADER_HEIGHT',
      display: 'HEADER_HEIGHT',
      isPasswordType: false,
      isBoolean: false
    },
    {
      key: 'LOGIN_BUTTON_STYLE',
      display: 'LOGIN_BUTTON_STYLE',
      isPasswordType: false,
      isBoolean: false
    },
    {
      key: 'HIDE_DRAWER_ICONS',
      display: 'HIDE_DRAWER_ICONS',
      isPasswordType: false,
      isBoolean: true
    },
    {
      key: 'HAS_SHADOW_CARD',
      display: 'HAS_SHADOW_CARD',
      isPasswordType: false,
      isBoolean: true
    },
    {
      key: 'CONTACT_NUMBER',
      display: 'CONTACT_NUMBER',
      isPasswordType: false,
      isBoolean: false
    },
    {
      key: 'HIDE_SALESFORCE_POP_UP',
      display: 'HIDE_SALESFORCE_POP_UP',
      isPasswordType: false,
      isBoolean: true
    },
    {
      key: 'HIDE_MENU_ARTICLES',
      display: 'HIDE_MENU_ARTICLES',
      isPasswordType: false,
      isBoolean: true
    },
    {
      key: 'REPEAT_VIDEO',
      display: 'REPEAT_VIDEO',
      isPasswordType: false,
      isBoolean: true
    },
    {
      key: 'SHOW_GATEWAY_BACKGROUND_VIDEO',
      display: 'SHOW_GATEWAY_BACKGROUND_VIDEO',
      isPasswordType: false,
      isBoolean: true
    },
    {
      key: 'CALLBACK_URL_AUTHO_NOT_USED_FOR_REFERENCE_IOS',
      display: 'CALLBACK_URL_AUTHO_NOT_USED_FOR_REFERENCE_IOS',
      isPasswordType: false,
      isBoolean: false
    },
    {
      key: 'CALLBACK_URL_AUTHO_NOT_USED_FOR_REFERENCE_ANDROID',
      display: 'CALLBACK_URL_AUTHO_NOT_USED_FOR_REFERENCE_ANDROID',
      isPasswordType: false,
      isBoolean: false
    },
    {
      key: 'HAS_INTRO_HUB',
      display: 'HAS_INTRO_HUB',
      isPasswordType: false,
      isBoolean: true
    },
    {
      key: 'STORAGE_URL',
      display: 'STORAGE_URL',
      isPasswordType: false,
      isBoolean: false
    },
    {
      key: 'HAS_LOGIN_BANNER',
      display: 'HAS_LOGIN_BANNER',
      isPasswordType: false,
      isBoolean: true
    },
    {
      key: 'DECK_SHADOW',
      display: 'DECK_SHADOW',
      isPasswordType: false,
      isBoolean: true
    },
    {
      key: 'SUPPORT_EMAIL',
      display: 'SUPPORT_EMAIL',
      isPasswordType: false,
      isBoolean: false
    },
    {
      key: 'OPEN_SUPPORT_AS_EMAIL',
      display: 'OPEN_SUPPORT_AS_EMAIL',
      isPasswordType: false,
      isBoolean: true
    },
    {
      key: 'HAS_SALES_INCENTIVE',
      display: 'HAS_SALES_INCENTIVE',
      isPasswordType: false,
      isBoolean: true
    },
    {
      key: 'GRID_STYLE',
      display: 'GRID_STYLE',
      isPasswordType: false,
      isBoolean: false
    },
    {
      key: 'HAS_INTRO_CARD_BACK',
      display: 'HAS_INTRO_CARD_BACK',
      isPasswordType: false,
      isBoolean: true
    },
    {
      key: 'SUPPORT_TEAM_TAGS',
      display: 'TEAM_TAGS',
      isPasswordType: false,
      isBoolean: true
    },
  ],
};


export const APPENV_QUERY = gql`
    query organization_app_env_variable_org{
      organization_app_env_variable_org{
        id
        ${APPENV_OPTIONS.fields.map((field) => field.key).join('\n')}
        _theme
        _image_theme
        _theme_versions
      }
    }
  `;

  export const UPDATE_THEME = gql`
    mutation createOrUpdateTheme(
      $ENV_NAME: String
      $theme: String
    ){
      createOrUpdateTheme(
        ENV_NAME: $ENV_NAME
        theme: $theme
      )
    }
  `;

export const APPENV_UPDATE = gql`
  mutation updateOrganizationAppEnvVariable (
    $id: ID!
    $input: OrganizationAppEnvVariableInput!
  ) {
  updateOrganizationAppEnvVariable(
    id: $id
    input: $input
    ){
      id
    }
  }`;

export const APPENV_CREATE = gql`
  mutation createOrganizationAppEnvVariable (
    $input: OrganizationAppEnvVariableInput!
  ) {
  createOrganizationAppEnvVariable(
    input: $input
    ){
      id
    }
  }
`;




// APIHOST: "cardware-admin-api.herokuapp.com",
//     APIPORT: null,
//     ASSET_HOST: "https://cardware-admin-api.herokuapp.com",
//     API_GRAPHQL: "https://cardware-admin-api.herokuapp.com/graphql",
//     API_PUSH_NOTIFICATION_HOST: "https://push-microservice.herokuapp.com",
//     ORG_NAME: "Cardware",
//     ENV_NAME: "cardware",
//     ANDROID_YOUTUBE_API_KEY: "AIzaSyAE35ih8soR9Fc0T9PDZcpGgyCv_cVYxD0",
//     IOS_YOUTUBE_API_KEY: "AIzaSyAVR3Z0pyXxSSQP48J7mFJVgtX17vTU0Bs",
//     INDEX_BG_COLOR: "#fff",
//     PW_RESET_REDIRECT_URL: "https://cardware-admin-api.herokuapp.com/reset-password",
//     MOBILE_TERMS_URL: "https://catalystxl.com/platform-terms-v1-0",
//     MOBILE_TOUR_URL: "https://success.catalystxl.com/nfp-tour",
//     SUPPORT_URL: "https://catalystxlhelp.zendesk.com",
//     SEGMENT_KEY: "c7V7Po5rTF8lKBZbmZRHdzMh6ncWAhAl",
//     AUTH0_CLIENT_ID: "LEPlrYVHCMBNpzUugkJG50dGVkgZmZYk",
//     AUTHO_DOMAIN: "cardwareadminbeta.us.auth0.com",
//     HIDE_SHADOW: true,
//     IS_SINGLE_THEME: true,
//     VERTICAL_SLIDER: true,
//     LARGE_DECK: false,
//     HIDE_ARTICLES: true,
//     HAS_PINCODE: false,
//     HEADER_HEIGHT: 50,
//     LOGIN_BUTTON_STYLE: null,
//     HIDE_DRAWER_ICONS: null,
//     HAS_SHADOW_CARD: null,
//     CONTACT_NUMBER: null,
//     HIDE_SALESFORCE_POP_UP: null,
//     HIDE_MENU_ARTICLES: null,
//     REPEAT_VIDEO: null,
//     SHOW_GATEWAY_BACKGROUND_VIDEO: null,
//     CALLBACK_URL_AUTHO_NOT_USED_FOR_REFERENCE_IOS: null,
//     CALLBACK_URL_AUTHO_NOT_USED_FOR_REFERENCE_ANDROID: null,
//     HAS_INTRO_HUB: null,
//     STORAGE_URL: null,
//     HAS_LOGIN_BANNER: null,
//     DECK_SHADOW: null,
//     SUPPORT_EMAIL: null,
//     OPEN_SUPPORT_AS_EMAIL: null,
//     HAS_SALES_INCENTIVE: null,
//     GRID_STYLE: null,
//     HAS_INTRO_CARD_BACK: null


// APIPROTOCOL
// APIHOST
// APIPORT
// ASSET_HOST
// API_GRAPHQL
// API_PUSH_NOTIFICATION_HOST
// ORG_NAME
// ENV_NAME
// ANDROID_YOUTUBE_API_KEY
// IOS_YOUTUBE_API_KEY
// INDEX_BG_COLOR
// PW_RESET_REDIRECT_URL
// MOBILE_TERMS_URL
// MOBILE_TOUR_URL
// SUPPORT_URL
// SEGMENT_KEY
// AUTH0_CLIENT_ID
// AUTHO_DOMAIN
// HIDE_SHADOW
// IS_SINGLE_THEME
// VERTICAL_SLIDER
// LARGE_DECK
// HIDE_ARTICLES
// HAS_PINCODE
// HEADER_HEIGHT
// LOGIN_BUTTON_STYLE
// HIDE_DRAWER_ICONS
// HAS_SHADOW_CARD
// CONTACT_NUMBER
// HIDE_SALESFORCE_POP_UP
// HIDE_MENU_ARTICLES
// REPEAT_VIDEO
// SHOW_GATEWAY_BACKGROUND_VIDEO
// CALLBACK_URL_AUTHO_NOT_USED_FOR_REFERENCE_IOS
// CALLBACK_URL_AUTHO_NOT_USED_FOR_REFERENCE_ANDROID
// HAS_INTRO_HUB
// STORAGE_URL
// HAS_LOGIN_BANNER
// DECK_SHADOW
// SUPPORT_EMAIL
// OPEN_SUPPORT_AS_EMAIL
// HAS_SALES_INCENTIVE
// GRID_STYLE
// HAS_INTRO_CARD_BACK