import * as React from 'react';
import { Grid, Cell } from 'baseui/layout-grid';
import { useStyletron } from 'baseui';
import {
  LabelSmall, LabelMedium
} from 'baseui/typography';
import { Skeleton } from 'baseui/skeleton'
import Button from '../styledButton';
import { Input } from 'baseui/input';
import { gridPaddingOverrides, cellPaddingOverrides } from '../overrides';
import { client, getServerUrl } from '../../apollo/client';
import { Spinner } from 'baseui/spinner';
import { APPENV_OPTIONS as AppEnvOptions, APPENV_QUERY as AppEnvQuery, APPENV_UPDATE as AppEnvUpdate, APPENV_CREATE, UPDATE_THEME } from './appenv-options';
import Toggle from 'react-toggle';
import 'react-toggle/style.css';
import {
  Modal,
  ModalBody,
  ModalFooter,
  ModalButton,
  SIZE,
  ROLE,
} from 'baseui/modal';
import { KIND as ButtonKind } from 'baseui/button';
import JSONInput from 'react-json-editor-ajrm';
import locale from 'react-json-editor-ajrm/locale/en';
import { Accordion, Panel } from "baseui/accordion";
import axios from 'axios';
import ConfirmationEditEnvModal from '../modals/confirm-edit-env-modal';
import { useDispatch } from 'react-redux';
import { showAlert } from '../../redux/actions/appBasicControls';
import DateRangeSelectorModal from '../modals/date-range-selector-modal';

const AppEnvironmentsSettings = ({ organization }) => {
  const [css, theme] = useStyletron();
  const [loading, setLoading] = React.useState(false);
  const [onSaveLoading, setOnSaveLoading] = React.useState(false);
  const [appEnv, setAppEnv] = React.useState({});
  const [error, setError] = React.useState(null);
  const [isConfirmationOpen, setIsConfirmationOpen] = React.useState(false);
  const [confirmationText, setConfirmationText] = React.useState('');
  const [isOpenClearInvestment, setIsOpenClearInvestment] = React.useState(false);
  const [isInvestmentClearing, setIsInvestmentClearing] = React.useState(false);
  const [isOpenDownloadModal, setIsOpenDowloadModal] = React.useState(false);
  const [_theme, setTheme] = React.useState({});
  const [_ImageTheme, setImageTheme] = React.useState({});

  const dispatch = useDispatch();

  const fetchData = () => {
    setLoading(true)
    client.query({ query: AppEnvQuery, fetchPolicy: 'network-only' })
      .then((data) => {
        setAppEnv(data.data.organization_app_env_variable_org);
        if (data.data.organization_app_env_variable_org && data.data.organization_app_env_variable_org._theme) {
          try {
            setTheme(JSON.parse(data.data.organization_app_env_variable_org._theme));
          } catch {

          }
        }
        if (data.data.organization_app_env_variable_org && data.data.organization_app_env_variable_org._image_theme) {
          try {
            setImageTheme(JSON.parse(data.data.organization_app_env_variable_org._image_theme));
          } catch {

          }
        }
        setLoading(false)
      }).catch(err => {
        setLoading(false)
        console.log(err);
      })
  };

  React.useEffect(() => {
    fetchData();
  }, []);

  React.useEffect(() => {
    if (appEnv === null) {
      client.mutate({
        mutation: APPENV_CREATE,
        variables: {
          input: AppEnvOptions.fields.reduce((a, b) => {
            a[b.key] = b.isBoolean ? false : '';
            a['HEADER_HEIGHT'] = 0;
            a['LOGIN_BUTTON_STYLE'] = 0;
            a['ENV_NAME'] = 'ENV_' + new Date().getTime();
            console.log(a);
            return a;
          }, {})
        }
      }).then(() => fetchData());
    }
  }, [appEnv]);

  const onSave = async () => {
    const { id, __typename, ...rest } = appEnv;
    delete rest._theme;
    delete rest._image_theme;
    setError(null);
    setOnSaveLoading(true);
    console.log(_theme);
    Promise.all(
      [
        client.mutate({
          mutation: AppEnvUpdate, variables: {
            id: id,
            input: rest
          }
        }),
        client.mutate({
          mutation: UPDATE_THEME,
          variables: {
            ENV_NAME: appEnv.ENV_NAME,
            theme: JSON.stringify(_theme)
          }
        }),
        client.mutate({
          mutation: UPDATE_THEME,
          variables: {
            ENV_NAME: "image",
            theme: JSON.stringify(_ImageTheme)
          }
        })
      ]
    ).then(([d1]) => {
      // console.log(d1);
      setOnSaveLoading(false);
      fetchData();
    }).catch(err => {
      setError(err.message);
      setOnSaveLoading(false);
    });
  };

  const onClearInvestments = async () => {
    try {
      setIsInvestmentClearing(true);
      const res = await axios({
        url: `${getServerUrl()}/remove_organization_users_cardware_form_responses/${organization.id}`,
        method: 'DELETE',
        // headers: {
        //   'Accept': 'application/json',
        //   'Content-Type': 'application/json'
        // }
      });
      // console.log('onClearInvestments response', res);
      setIsInvestmentClearing(false);
      dispatch(
        showAlert({
          msg: 'Investments cleared successfully',
          error: false,
        })
      );
    } catch (err) {
      console.log('onClearInvestments error', err);
      setIsInvestmentClearing(false);
      dispatch(
        showAlert({
          msg: err?.message || 'Something went wrong, Please try again later',
          error: true,
        })
      );
    }
  }

  const onDownloadReponseClick = async (start_date, end_date) => {
    console.log('onDownloadReponseClick', { start_date, end_date, organization_id: organization.id })
    window.open(`${getServerUrl()}/generate_organization_responses_csv/${organization.id}?start_date=${start_date}&&end_date=${end_date}`, '_blank')
  }

  const onClickClearUserInvestments = () => {
    setIsOpenClearInvestment(true);
  }

  const onClickDownloadResponses = () => {
    setIsOpenDowloadModal(true);
  }

  const getEnvFieldValue = (appEnv, fieldKey) => {
    if (appEnv && appEnv !== null) {
      return appEnv[fieldKey];
    }

    return '';
  }


  console.log('_theme', _theme);

  if (loading) {
    return (
      <div style={{ width: '100%', }}>
        <div style={{ marginLeft: '2rem', marginTop: '1rem' }}>
          {new Array(10).fill('').map(item => {
            return (
              <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', marginTop: '2rem' }}>
                {new Array(2).fill('').map(item => <Skeleton
                  width="48%"
                  height="80px"
                  animation
                  overrides={{
                    Root: {
                      style: {
                        marginRight: '60px'
                      },
                    },
                  }}
                />)}
              </div>
            )
          })}
        </div>
      </div>
    );
  }

  return (
    <div>
      <Grid overrides={gridPaddingOverrides}>
        <Cell overrides={cellPaddingOverrides} span={12}>
          <div style={{ position: 'relative', float: 'right' }}>
            <Button isSelected isLoading={onSaveLoading} style={{ marginLeft: '16px' }} onClick={() => setIsConfirmationOpen(true)}>Save</Button>
          </div>
          <div style={{ position: 'relative', float: 'right', marginTop: '22px' }}>
            {error && <LabelSmall style={{ color: 'red' }}>{error}</LabelSmall>}
          </div>
        </Cell>
        <div style={{ marginTop: '16px', width: '100%' }}>
          <Grid overrides={gridPaddingOverrides}>
            <Cell overrides={cellPaddingOverrides} span={12}>
              <div
                className={css({
                  padding: '24px',
                  background: theme.colors.primaryB,
                  marginBottom: '24px',
                  marginLeft: '12px',
                  marginRight: '12px',
                  boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)'
                })}
              >
                <Grid overrides={gridPaddingOverrides}>
                  {AppEnvOptions.fields.sort((a, b) => {
                    return (a.isBoolean ? 1 : 0) - (b.isBoolean ? 1 : 0);
                  }).map((field) => {
                    const fieldData = getEnvFieldValue(appEnv, field.key);
                    return <Cell span={6}>
                      {field.isBoolean == true &&
                        <>
                          <hr style={{ height: '2px', margin: 0, marginTop: '12px', marginBottom: '12px', background: '#E2E2E2' }} />
                          <div className={'setting-toggle-container'} style={{ display: 'flex', flexDirection: 'row', alignContent: 'center' }}>
                            <Toggle
                              id="cheese-status"
                              defaultChecked={fieldData}
                              icons={false}
                              onChange={e => {
                                setAppEnv({ ...appEnv, [field.key]: e.target.checked });
                              }}
                            ></Toggle><p style={{ color: 'black', fontSize: '16px', fontWeight: 500, margin: 0, marginLeft: '0.7rem', marginTop: '3px' }}>{field.display}</p>
                          </div>
                        </>}

                      {field.isBoolean == false && <>
                        <LabelSmall
                          style={{
                            marginTop: '16px',
                            marginBottom: '8px',
                          }}
                        >
                          {field.display}
                        </LabelSmall>
                        <Input
                          value={fieldData}
                          onChange={e => {
                            setAppEnv({ ...appEnv, [field.key]: e.target.value });
                          }}
                          type={field.isPasswordType ? 'password' : 'text'}
                          placeholder={field.placeholder}
                          clearOnEscape
                          disabled={field.disabled}
                        />
                      </>}
                    </Cell>
                  })}
                  <Cell
                    span={6}
                    overrides={{
                      Cell: {
                        style: () => ({
                          display: 'flex !important',
                          justifyContent: 'flex-end',
                          alignItems: 'flex-end',
                          flexDirection: 'column'
                        }),
                      }
                    }}
                  >
                    <hr style={{ height: '2px', margin: 0, marginTop: '12px', marginBottom: '12px', background: '#E2E2E2', width: '100%' }} />
                    <div style={{ display: 'flex', flexDirection: 'row', alignContent: 'center' }}>
                      <Button
                        isSelected
                        isLoading={isInvestmentClearing}
                        onClick={onClickClearUserInvestments}
                        style={{
                          fontSize: '16px', fontWeight: 500,
                        }}
                      >
                        CLEAR USERS INVESTMENTS
                      </Button>
                    </div>
                  </Cell>
                  <Cell
                    span={6}
                    overrides={{
                      Cell: {
                        style: () => ({
                          display: 'flex !important',
                          flexDirection: 'column'
                        }),
                      }
                    }}
                  >
                    <hr style={{ height: '2px', margin: 0, marginTop: '12px', marginBottom: '12px', background: '#E2E2E2', width: '100%' }} />
                    <div style={{ display: 'flex', flexDirection: 'row', alignContent: 'center' }}>
                      <Button
                        isSelected
                        onClick={onClickDownloadResponses}
                        style={{
                          fontSize: '16px', fontWeight: 500,
                        }}
                      >
                        DOWNLOAD RESPONSES
                      </Button>
                    </div>
                  </Cell>
                </Grid>
              </div>
            </Cell>
          </Grid>
        </div>


        <div style={{ marginTop: '16px', width: '100%' }}>
          <Grid overrides={gridPaddingOverrides}>
            <Cell overrides={cellPaddingOverrides} span={12}>
              <div
                className={css({
                  padding: '24px',
                  background: theme.colors.primaryB,
                  marginBottom: '24px',
                  marginLeft: '12px',
                  marginRight: '12px',
                  boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)'
                })}
              >
                <Grid overrides={{
                  Grid: {
                    style: () => ({
                      padding: '0px !important',
                      marginBottom: '16px'
                    }),
                  },
                }}>
                  <LabelMedium>THEME</LabelMedium>
                </Grid>
                <Grid overrides={gridPaddingOverrides}>
                  <div className={'setting-toggle-container'} style={{ display: 'flex', flexDirection: 'row', alignContent: 'center' }}>
                    <Toggle
                      id="cheese-status"
                      defaultChecked={_theme.has_cardware_login || false}
                      icons={false}
                      onChange={e => {
                        setTheme({
                          ..._theme,
                          has_cardware_login: e.target.checked
                        })
                      }}
                    ></Toggle><p style={{ color: 'black', fontSize: '16px', fontWeight: 500, margin: 0, marginLeft: '0.7rem', marginTop: '3px' }}>Use new cardware login page</p>
                  </div>
                </Grid>
                <Grid overrides={{
                  Grid: {
                    style: () => ({
                      padding: '0px !important',
                      marginTop: '16px'
                    }),
                  },
                }}>
                  <Accordion>
                    <Panel title="Advance theme editor">
                      <JSONInput
                        id='a_unique_id'
                        placeholder={_theme}
                        theme="dark_vscode_tribute"
                        colors={{
                          string: "#DAA520" // overrides theme colors with whatever color value you want
                        }}
                        locale={locale}
                        height='550px'
                        width={'60vw'}
                        onChange={e => {
                          // console.log(e);
                          !e.error && setTheme(e.jsObject);
                        }}
                      />
                    </Panel>
                  </Accordion>
                </Grid>
                <Grid overrides={{
                  Grid: {
                    style: () => ({
                      padding: '0px !important',
                      marginTop: '16px'
                    }),
                  },
                }}>
                  <Accordion>
                    <Panel title="Image size configuration">
                      <JSONInput
                        id='aa_unique_id'
                        placeholder={_ImageTheme}
                        theme="dark_vscode_tribute"
                        colors={{
                          string: "#DAA520" // overrides theme colors with whatever color value you want
                        }}
                        locale={locale}
                        height='550px'
                        width={'60vw'}
                        onChange={e => {
                          // console.log(e);
                          !e.error && setImageTheme(e.jsObject);
                        }}
                      />
                    </Panel>
                  </Accordion>
                </Grid>
              </div>
            </Cell>
          </Grid>
        </div>


      </Grid>
      {/* <Modal
        onClose={() => {
          setConfirmationText('');
          setIsConfirmationOpen(false);
        }}
        closeable
        isOpen={isConfirmationOpen}
        animate
        autoFocus
        size={SIZE.default}
        role={ROLE.dialog}
        overrides={{
          Dialog: {
            style: {
              width: '70vw',
              display: 'flex',
              flexDirection: 'column',
            },
          },
          Backdrop: {
            style: ({ $theme }) => ({
              backdropFilter: $theme.modalBackDropFilter,
            }),
          },
          Root: {
            style: {
              zIndex: 3
            }
          }
        }}
      >
        <ModalBody>
          <LabelMedium style={{
            marginTop: '32px'
          }}>
            Please enter "CONFIRM" below, as you are updating the advanced settings page and any changes can potentially cause functional disruption in your app.
          </LabelMedium>
          <div style={{ marginTop: '32px' }}>
            <Input
              value={confirmationText}
              onChange={e => {
                setConfirmationText(e.target.value);
              }}
              placeholder={'type CONFIRM'}
            />
          </div>
        </ModalBody>
        <ModalFooter>
          <ModalButton
            onClick={() => {
              if (confirmationText == 'CONFIRM') {
                setConfirmationText('');
                setIsConfirmationOpen(false);
                onSave();
              }
            }}
            disabled={confirmationText != 'CONFIRM'}
            style={{ textTransform: 'capitalize' }}
          >
            SAVE
          </ModalButton>
        </ModalFooter>
      </Modal> */}

      <ConfirmationEditEnvModal
        isOpen={isConfirmationOpen}
        onClose={() => {
          setIsConfirmationOpen(false);
        }}
        onButtonClick={onSave}
      />

      <ConfirmationEditEnvModal
        modalText={`Are you sure, you wanna delete every investment of each and every user? \n Please enter "CONFIRM" below`}
        isOpen={isOpenClearInvestment}
        onClose={() => {
          setIsOpenClearInvestment(false)
        }}
        btnText={'CLEAR'}
        onButtonClick={onClearInvestments}
      />
      <DateRangeSelectorModal
        isOpen={isOpenDownloadModal}
        onClose={() => {
          setIsOpenDowloadModal(false)
        }}
        onButtonClick={onDownloadReponseClick}
      />
    </div >
  )
}

export default AppEnvironmentsSettings;