import * as React from 'react';
import { apiQuery } from '../apiMappings/query';
import { client } from '../../apollo/client';
import { Tabs, Tab, FILL } from "baseui/tabs-motion";
import { gridPaddingOverrides, cellPaddingOverrides } from '../overrides';
import { Grid, Cell } from 'baseui/layout-grid';
import { Spinner } from 'baseui/spinner';
import Button from '../styledButton';
import { useStyletron } from 'baseui';

import BaseApi from './base-api';
import Paths from './paths';
import { getCrunchbaseIntegrations } from '../apiMappings/query';

export default ({ organization, dispatch, refetch }) => {

  const organization_id = organization.id;
  const [css, theme] = useStyletron();
  const [apis, setApis] = React.useState([]);
  const [paths, setPaths] = React.useState([]);
  const [activeKey, setActiveKey] = React.useState(0);
  const [isLoading, setIsLoading] = React.useState(false);
  const [crunchbaseIntegrations, setCrunchbaseIntegrations] = React.useState([]);

  const queryData = () => {
    setIsLoading(true);
    client.query({
      query: apiQuery(),
      fetchPolicy: 'network-only',
      variables: {
        data: {
          model: "api_paths",
          query: `{\"where\": {\"organization_id\": \"${organization_id}\"}}`
        }
      }
    }).then((result) => {
      let response = result.data.cxl_internal_common_query;
      if (response.status) {
        setPaths(JSON.parse(response.data));
      }
      setIsLoading(false);
    });

    client.query({
      query: apiQuery(),
      fetchPolicy: 'network-only',
      variables: {
        data: {
          model: "organization_apis",
          query: `{\"where\": {\"organization_id\": \"${organization_id}\"}}`
        }
      }
    }).then((result) => {
      let response = result.data.cxl_internal_common_query;
      if (response.status) {
        setApis(JSON.parse(response.data));
      }
    });

    client.query({
      query: getCrunchbaseIntegrations(organization_id),
      fetchPolicy: 'network-only',
      variables: {}
    }).then((result) => {
      let response = result.data.all_crunchbase_integration_by_org;
      setCrunchbaseIntegrations(response);
    });
  };


  React.useEffect(() => {
    queryData();
  }, []);

  console.log(apis, paths);
  return <Grid overrides={gridPaddingOverrides}>
    {/* <Cell span={12} align={'right'}>
      <div
        className={css({
          marginRight: '16px',
          position: 'relative',
          float: 'right',
          marginBottom: '16px'
        })}
      >
        <Button
          isLoading={false}
          onClick={() => {

          }}
          isSelected
        >
          Add New
          </Button>
      </div>
      {isLoading && <div
        className={css({
          position: 'relative',
          float: 'right',
          marginRight: '16px', marginTop: '18px'
        })}
      >
        <Spinner size={24} />
      </div>}
    </Cell> */}
    <Cell span={12} overrides={cellPaddingOverrides}>
      <Tabs
        activeKey={activeKey}
        onChange={({ activeKey }) => {
          setActiveKey(activeKey);
        }}
        activateOnFocus
        overrides={{
          Root: {
            style: () => ({
              padding: 0,
            })
          }
        }}
      >
        <Tab title="Base Api">
          <BaseApi apis={apis} queryData={queryData} isLoading={isLoading} setIsLoading={setIsLoading} setApis={setApis} organization_id={organization_id} dispatch={dispatch} refetch={refetch}/>
        </Tab>
        <Tab title="Api Paths">
          <Paths apis={apis} paths={paths} queryData={queryData} isLoading={isLoading} setIsLoading={setIsLoading} setPaths={setPaths} organization_id={organization_id} dispatch={dispatch} refetch={refetch} crunchbaseIntegrations={crunchbaseIntegrations}/>
        </Tab>
      </Tabs>
    </Cell>
  </Grid>
};