import * as React from 'react';
import { useState } from "react";
import {
  Modal,
  ModalBody,
  ModalFooter,
  ModalButton,
  SIZE,
  ROLE,
} from 'baseui/modal';
import { KIND as ButtonKind } from 'baseui/button';
import {
   LabelMedium
} from 'baseui/typography';
import { Input } from 'baseui/input';


const ConfirmationEditEnvModal = ({
  modalText,
  isOpen,
  onClose = () => {},
  btnText,
  onButtonClick = () => {}
}) => {
  const [confirmationText, setConfirmationText] = useState('');

  const onChangeConfirmationText = e => {
    setConfirmationText(e.target.value);
  }

  const onClickSave = () => {
    if (confirmationText == 'CONFIRM') {
      setConfirmationText('');
      onClose();
      onButtonClick();
    }
  }

  const onModalClose  = () => {
    setConfirmationText('');
    onClose()
  }

  return (
    <Modal
      onClose={onModalClose}
      closeable
      isOpen={isOpen}
      animate
      autoFocus
      size={SIZE.default}
      role={ROLE.dialog}
      overrides={{
        Dialog: {
          style: {
            width: '70vw',
            display: 'flex',
            flexDirection: 'column',
          },
        },
        Backdrop: {
          style: ({ $theme }) => ({
            backdropFilter: $theme.modalBackDropFilter,
          }),
        },
        Root: {
          style: {
            zIndex: 3
          }
        }
      }}
    >
      <ModalBody>
        <LabelMedium style={{
          marginTop: '32px'
        }}>
          {modalText || 'Please enter "CONFIRM" below, as you are updating the advanced settings page and any changes can potentially cause functional disruption in your app.'}
        </LabelMedium>
        <div style={{ marginTop: '32px' }}>
          <Input
            value={confirmationText}
            onChange={onChangeConfirmationText}
            placeholder={'type CONFIRM'}
          />
        </div>
      </ModalBody>
      <ModalFooter>
        <ModalButton
          onClick={onClickSave}
          disabled={confirmationText != 'CONFIRM'}
          style={{ textTransform: 'capitalize' }}
        >
          {btnText || 'SAVE'}
        </ModalButton>
      </ModalFooter>
    </Modal >
  )
}

export default ConfirmationEditEnvModal;