import axios from 'axios';
import React, { useEffect, useMemo } from 'react';
import { debounce } from 'lodash';
import {
  ParagraphSmall,
  ParagraphXSmall,
  LabelSmall
} from 'baseui/typography';
import { Select } from 'baseui/select';
import Button from '../styledButton';
import 'react-toggle/style.css';

import { client } from '../../apollo/client';
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  ModalButton,
} from 'baseui/modal';
import _ from 'lodash';
import { showAlert } from '../../redux/actions/appBasicControls';
import { renderInputField, renderSelectField } from '../settings/common-components/CustomRenderFunctions';
import { useQuery } from '@apollo/client';
import { microsoftIntegrations, microsoft_integration, updateMicrosoftIntegrationByRefreshToken } from '../settings/queries';

const Fields = [
  {
    key: 'name',
    display: 'Name',
    isPasswordType: false
  }]

const PopOverride = {
  Popover: {
    props: {
      overrides: {
        Body: { style: { zIndex: 100 } },
      },
    },
  },
}


const AddSharePointSheetModal = (props) => {
  const {
    integrations,
    organization,
    integrationData,
    isModalOpen,
    dispatch,
    onClose,
    onSuccess
  } = props;

  const [sharePointAuth, setSharePointAuth] = React.useState(integrationData || {});
  const [siteList, setSiteList] = React.useState([]);
  const [driveList, setDriveList] = React.useState([]);
  const [driveItemList, setDriveItemList] = React.useState([]);
  const [isSitesLoading, setIsSitesLoading] = React.useState(false);
  const [isModalLoading, setIsModalLoading] = React.useState(false);
  const [sharePointAuthLoader, setSharePointAuthLoader] = React.useState({});
  const { loading, data: microsoftIntegrationsData, refetch: refetchMicrosoftIntegration } = useQuery(microsoftIntegrations, {
    variables: {
      orgId: organization.id
    }
  });

  var _searchSharePointDriveItemList = null;

  useEffect(() => {
    refetchMicrosoftIntegration();
  }, []);

  useEffect(() => {
    const {
      id,
      microsoft_integration_id
    } = integrationData;

    setSharePointAuth(integrationData || {});
    if (id) {
      debugger
      getMicrosoftIntegrationDetails(microsoft_integration_id, (intRes) => {
        debugger
        setSharePointAuth({
          ...integrationData,
          access_token: intRes.access_token
        });
        getSharePointSiteList('', intRes.access_token);
      })

    }

  }, [integrationData]);

  useEffect(() => {
    _searchSharePointDriveItemList = _.debounce(searchSharePointDriveItemList, 1000)
  },[sharePointAuth, sharePointAuthLoader])

  const getMicrosoftIntegrationDetails = async (id, onSuccess) => {
    return client.query({
      query: microsoft_integration,
      variables: {
        id
      }
    }).then(({ data }) => {
      const {
        microsoft_integration
      } = data || {};
      onSuccess(microsoft_integration)
    }).catch((err) => console.log('err', err))
  }

  const microsoftCallbackApi = async (err) => {
    const errString = err && err.toString() || '';
    if (errString.includes('401')) {
      const updatedRes = await getTokenByRefreshToken(sharePointAuth['microsoft_integration_id']);
      debugger
      if (updatedRes.success) {
        setSharePointAuth({
          ...sharePointAuth,
          access_token: updatedRes.access_token,
        });
        await refetchMicrosoftIntegration();
        dispatch(showAlert({
          msg: 'Please try again',
          error: true,
        }));
      }
    }
  }

  const getSharePointSiteList = (siteName = '', access_token = sharePointAuth.access_token) => {
    const {
      site_id,
    } = integrationData || {};

    const siteId = sharePointAuth.id || site_id;
    setIsSitesLoading(true);
    let accessToken = access_token;
    if (!accessToken) {
      accessToken = getMicrosoftSheetAccessToken();
    }
    if (!accessToken) {
      setIsSitesLoading(false);
      dispatch(showAlert({
        msg: 'Integration not authorized',
        error: true
      }))
      return;
    }
    axios({
      method: 'GET',
      url: `https://graph.microsoft.com/v1.0/sites?search=${siteName}`,
      headers: {
        'Authorization': 'Bearer ' + accessToken
      }
    }).then(responseJson => {
      const {
        data
      } = responseJson || {};
      if (data && data.value) {
        setSiteList(data.value);
        if (data.value.length > 0) {
          getSharePointDriveList(siteId || data.value[0].id, accessToken);
        }
      } else { setSiteList([]); }
    }).catch(async (err) => {
      await microsoftCallbackApi(err);
    }).finally(() => setIsSitesLoading(false));
  }

  const getSharePointDriveList = (site_id, access_token = sharePointAuth.access_token) => {

    setSharePointAuthLoader({
      ...sharePointAuthLoader,
      'drive_loader': true
    });
    axios({
      method: 'GET',
      url: `https://graph.microsoft.com/v1.0/sites/${site_id}/drives`,
      headers: {
        'Authorization': 'Bearer ' + access_token
      }
    }).then(responseJson => {
      const {
        data
      } = responseJson || {};
      if (data && data.value) {
        setDriveList(data.value);
        if (data.value.length > 0) {
          getSharePointDriveItemList(data.value[0].id, site_id, access_token,);
        }
      } else { setDriveList([]); }
    }).catch(async (err) => {
      await microsoftCallbackApi(err);
    })
      .finally(() => {
        setSharePointAuthLoader({
          ...sharePointAuthLoader,
          'drive_loader': false
        });
      })
  }

  const getSharePointDriveItemList = (drive_id, siteId = '', access_token = sharePointAuth.access_token) => {
    setSharePointAuthLoader({
      ...sharePointAuthLoader,
      'drive_item_loader': true
    });
    const {
      site_id
    } = sharePointAuth;
    axios({
      method: 'GET',
      url: `https://graph.microsoft.com/v1.0/sites/${site_id || siteId}/drives/${drive_id}/search(q='')`,
      headers: {
        'Authorization': 'Bearer ' + access_token
      }
    }).then(responseJson => {
      const {
        data
      } = responseJson || {};
      if (data && data.value) {
        setDriveItemList(data.value);
      } else { setDriveItemList([]); }
    }).catch(async (err) => {
      await microsoftCallbackApi(err);
    }).finally(() => {
      setSharePointAuthLoader({
        ...sharePointAuthLoader,
        'drive_item_loader': false
      });
    })
  }

  const searchSharePointDriveItemList = (drive_id, searchText, siteId = '', access_token = sharePointAuth.access_token) => {
    setSharePointAuthLoader({
      ...sharePointAuthLoader,
      'drive_item_loader': true
    });
    const {
      site_id
    } = sharePointAuth;
    let url = `https://graph.microsoft.com/v1.0/sites/${site_id || siteId}/drives/${drive_id}/search(q='')`;

    if (searchText) {
      url = `https://graph.microsoft.com/v1.0/sites/${site_id || siteId}/drives/${drive_id}/search(q='${searchText}')`
    }

    axios({
      method: 'GET',
      url,
      headers: {
        'Authorization': 'Bearer ' + access_token
      }
    }).then(responseJson => {
      const {
        data
      } = responseJson || {};
      if (data && data.value) {
        setDriveItemList(data.value);
      } else { setDriveItemList([]); }
    }).catch(async (err) => {
      await microsoftCallbackApi(err);
    }).finally(() => {
      setSharePointAuthLoader({
        ...sharePointAuthLoader,
        'drive_item_loader': false
      });
    })
  }


  const getMicrosoftSheetAccessToken = () => {
    return sharePointAuth['access_token']
  }

  const getTokenByRefreshToken = async () => {
    return client.mutate({
      mutation: updateMicrosoftIntegrationByRefreshToken,
      variables: {
        id: sharePointAuth['microsoft_integration_id']
      }
    }).then(({ data }) => {
      const {
        updateMicrosoftIntegrationByRefreshToken: {
          success,
          microsoft_integration
        }
      } = data || {};

      if (success) {
        const {
          access_token,
        } = microsoft_integration || {};
        if (access_token) {
          setSharePointAuth({
            ...sharePointAuth,
            access_token
          })
          return ({
            success,
            access_token
          });
        }
      }

      return ({
        success
      });
    }).catch((err) => {
      console.log('errr', err)
      return ({
        success: false,
        error: err
      });
    })
  }

  const onChangeSiteText = (e) => {
    let getSiteList = debounce(getSharePointSiteList, 1000);
    getSiteList(e.target.value)
  }

  const siteVal = {
    id: sharePointAuth['site_id'],
    name: sharePointAuth['site_name'],
  };

  const driveVal = {
    id: sharePointAuth['drive_id'],
    name: sharePointAuth['drive_name'],
  };

  const spreadsheetVal = {
    id: sharePointAuth['spreadsheet_id'],
    name: sharePointAuth['spreadsheet_name']
  }

  const microsoftIntegrationVal = {
    id: sharePointAuth['microsoft_integration_id'],
    name: sharePointAuth['microsoft_integration_name']
  }

  const onAddSharePoint = async () => {
    const {
      name,
      microsoft_integration_id,
      site_id,
      site_name,
      drive_id,
      drive_name,
      spreadsheet_id
    } = sharePointAuth || {};
    setIsModalLoading(true);
    await client.mutate({
      mutation: integrations[0].mutations.create,
      variables: {
        organization_id: organization.id,
        name,
        microsoft_integration_id,
        site_id,
        site_name,
        drive_id,
        drive_name,
        spreadsheet_id
      }
    }).then(() => {
      dispatch(
        showAlert({
          msg: 'Successfully saved integration!',
          error: false,
        })
      );
    })
      .catch((err) => {
        dispatch(
          showAlert({
            msg: 'Something went wrong integration not saved!',
            error: false,
          })
        );
      });

    await onSuccess();
    setIsModalLoading(false);
    setSharePointAuth({});
    setSiteList([]);
    setDriveList([]);

  }

  const onUpdateSharePoint = async () => {
    const {
      id,
      name,
      microsoft_integration_id,
      site_id,
      site_name,
      drive_id,
      drive_name,
      spreadsheet_id
    } = sharePointAuth || {};
    setIsModalLoading(true);
    let updatePayload = {
      id,
      name,
      microsoft_integration_id,
      site_id,
      site_name,
      drive_id,
      drive_name,
      spreadsheet_id
    };

    delete updatePayload.access_token;
    delete updatePayload.organization_id;
    delete updatePayload.spreadsheet_name;
    await client.mutate({
      mutation: integrations[0].mutations.update,
      variables: {
        ...updatePayload
      }
    }).catch((err) => {
      dispatch(
        showAlert({
          msg: 'Something went wrong integration not updated!',
          error: false,
        })
      );
    });

    // await fetchIntegrations();
    // await refetch();
    await onSuccess();
    setIsModalLoading(false);
    setSharePointAuth({});
    setSiteList([]);
    setDriveList([]);
  }

  const onChangeSite = (e) => {
    setSharePointAuth({
      ...sharePointAuth,
      site_id: e.option.id,
      site_name: e.option.name,
      drive_id: '',
      drive_name: '',
      spreadsheet_id: '',
      spreadsheet_name: '',
    })
    getSharePointDriveList(e.option.id)
  }

  const onChangeDrive = (e) => {
    setSharePointAuth({
      ...sharePointAuth,
      drive_id: e.option.id,
      drive_name: e.option.name,
      spreadsheet_id: '',
      spreadsheet_name: '',
    })
    getSharePointDriveItemList(e.option.id)
  }

  const onChangeDriveItem = (e) => {
    setSharePointAuth({
      ...sharePointAuth,
      spreadsheet_id: e.option.id,
      spreadsheet_name: e.option.name,
    })
  }

  const onChangeMicrosoftIntegration = (e) => {
    debugger
    console.log(microsoftIntegrationsData)
    const {
      microsoft_integrations = []
    } = microsoftIntegrationsData || {};
    const selectedIntegration = microsoft_integrations && microsoft_integrations.find(item => item.id === e.option.id) || {};
    debugger
    setSharePointAuth({
      ...sharePointAuth,
      microsoft_integration_id: e.option.id,
      microsoft_integration_name: e.option.name,
      access_token: selectedIntegration.access_token,
      site_id: '',
      site_name: '',
      drive_id: '',
      drive_name: '',
      spreadsheet_id: '',
      spreadsheet_name: '',
    })
    // getSharePointDriveList(e.option.id)
  }

  const onClickSave = () => {
    const {
      id,
    } = sharePointAuth || {};
    if (id) {
      onUpdateSharePoint();
    } else {
      onAddSharePoint();
    }
  }

  const onCloseModal = () => {
    setSharePointAuth({});
    setSiteList([]);
    setDriveList([]);
    onClose();
  }

  const onRefreshSiteList = () => {
    getSharePointSiteList();
  }

  const onRefreshDriveList = () => {
    getSharePointDriveList(sharePointAuth.site_id || '');
  }

  const onRefreshDriveItemList = () => {
    getSharePointDriveItemList(sharePointAuth.drive_id || '');
  }

  // Render Function
  const renderSelect = (title, description, value, valKey, labelKey, options, onChange, isLoading, onInputChange = () => { }, onClose = () => { }) => {
    return (
      <div
        style={{
          marginTop: '16px',
          marginBottom: '8px',
        }}
      >
        <LabelSmall
          style={{
            marginTop: '16px',
            marginBottom: '8px',
          }}
        >
          {title}
        </LabelSmall>
        <ParagraphSmall
          style={{
            marginTop: '16px',
            marginBottom: '8px',
          }}
        >{description}</ParagraphSmall>
        <Select
          isLoading={isLoading}
          value={value}
          valueKey={valKey}
          labelKey={labelKey}
          options={options}
          onChange={onChange}
          overrides={PopOverride}
          onInputChange={onInputChange}
          onClose={onClose}
        />
      </div>
    )
  }

  const renderMicrosoftIntegrationList = () => {
    const {
      microsoft_integrations = []
    } = microsoftIntegrationsData || {};
    return renderSelect("Microsoft Integration", 'Select which microsoft integration to use with this integration', microsoftIntegrationVal, 'id', 'name', microsoft_integrations, onChangeMicrosoftIntegration);
  }

  const renderSitesList = () => {
    const showSiteList = siteList && siteList.length > 0;

    if (showSiteList) {
      return (
        <>
          {renderSelect("Site", '', siteVal, 'id', 'name', siteList, onChangeSite, isSitesLoading)}
        </>
      );
    }
    return null;
  }

  const renderDriveList = () => {
    const showSiteList = siteList && siteList.length > 0;
    const showDriveList = driveList && driveList.length > 0;
    const driveLoader = sharePointAuthLoader && sharePointAuthLoader.drive_loader || false || false;

    if (showSiteList && showDriveList) {
      return (
        <>
          {renderSelect("Drive", '', driveVal, 'id', 'name', driveList, onChangeDrive, driveLoader)}
          <Button
            style={{ background: 'black' }}
            isLoading={isSitesLoading}
            onClick={onRefreshDriveList}
          >Refresh Drives</Button>
        </>
      );
    }
    return null;
  }

  const renderDriveItemList = () => {
    const showSiteList = siteList && siteList.length > 0;
    const showDriveList = driveList && driveList.length > 0;
    const showDriveItemList = driveItemList && driveItemList.length > 0;
    const driveItemLoader = sharePointAuthLoader && sharePointAuthLoader.drive_item_loader || false || false;

    if (showSiteList && showDriveList && showDriveItemList) {
      const onChangeInput = (event) => {
        console.log('onChangeInput', event.target.value)
        _searchSharePointDriveItemList(sharePointAuth.drive_id || '', event?.target?.value)
        // searchSharePointDriveItemList(sharePointAuth.drive_id || '', event?.target?.value)
      }
      return (
        <>
          {renderSelect("Drive Item", '', spreadsheetVal, 'id', 'name', driveItemList, onChangeDriveItem, driveItemLoader, onChangeInput, onRefreshDriveItemList)}
          <Button
            style={{ background: 'black' }}
            isLoading={isSitesLoading}
            onClick={onRefreshDriveItemList}
          >Refresh Item List</Button>
        </>
      );
    }
    return null;
  }

  const renderFields = (item, index) => {
    const onChange = (e) => {
      setSharePointAuth({
        ...sharePointAuth,
        [item.key]: e.target.value
      })
    }
    const fieldData = {
      ...item,
      value: sharePointAuth[item.key]
    }
    // console.log('fieldData', fieldData, item);
    return renderInputField(fieldData, onChange);
  }

  const {
    id
  } = sharePointAuth || {};
  const saveText = useMemo(() => id && 'Update' || 'Save', [id]);
  const headerText = useMemo(() => id && 'Update Share Point Integration' || 'Add Share Point Integration', [id]);

  const showAddButton = useMemo(() => {
    const {
      name,
      microsoft_integration_id,
      site_id,
      site_name,
      drive_id,
      drive_name,
      spreadsheet_id
    } = sharePointAuth || {};
    return microsoft_integration_id && site_id && site_name && drive_id && drive_name && spreadsheet_id
  }, [sharePointAuth])

  // console.log('sharePointAuth', sharePointAuth, integrationData);

  return (
    <React.Fragment>
      <Modal
        overrides={{
          Root: {
            style: () => ({
              zIndex: 1
            })
          }
        }}
        onClose={onCloseModal}
        isOpen={isModalOpen}
      >
        <ModalHeader>{headerText}</ModalHeader>
        <ModalBody>
          {Fields.map(renderFields)}
          {renderMicrosoftIntegrationList()}
          <Button
            style={{ background: 'black' }}
            isLoading={isSitesLoading}
            onClick={() => getSharePointSiteList()}
          >Get Sites</Button>
          {renderSitesList()}
          {renderDriveList()}
          {renderDriveItemList()}
        </ModalBody>
        <ModalFooter>
          <ModalButton
            onClick={onCloseModal}
          >
            Cancel
          </ModalButton>
          {
            showAddButton && (
              <ModalButton
                isLoading={isModalLoading}
                onClick={onClickSave}
              >
                {saveText}
              </ModalButton>
            )
          }

        </ModalFooter>
      </Modal>
    </React.Fragment>
  )
}



export default AddSharePointSheetModal;