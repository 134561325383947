import moment from 'moment';
import _ from 'lodash';

export const getTimeRange = (tab) => {
  if (!tab) {
    return {
      start_time: moment('01-01-1970', 'YYYY-MM-DD').toDate().toISOString(),
      end_time: moment().toDate().toISOString(),
      date_description: 'App Launch - Till Today'
    }
  };

  if (tab == 'Weekly' || tab == 'Daily' || tab == 'Monthly') {
    return {
      start_time: moment().startOf('year').toDate().toISOString(),
      end_time: moment().toDate().toISOString(),
      date_description: `${moment().startOf('year').format('ll')} - Till Today`
    }
  };

  if (tab == 'Yearly') {
    return {
      start_time: moment('01-01-1970', 'YYYY-MM-DD').toDate().toISOString(),
      end_time: moment().toDate().toISOString(),
      date_description: 'App Launch - Till Today'
    }
  };

  return {
    start_time: moment().toDate().toISOString(),
    end_time: moment().toDate().toISOString(),
    date_description: 'Today'
  }
};


export const groupDataByTP = (d, tab) => {
  const _getFormatter = () => {
    switch (tab) {
      case 'Daily':
        return 'll';
      case 'Weekly':
        return '[Week] W';
      case 'Monthly':
        return 'MMM YY';
      case 'Yearly':
        return 'YYYY';
    };
    return 'll';
  };
  d = d.map(el => {
    return {
      y: 1 * el.count,
      _x: el.date,
      x: moment(el.date, 'YYYY-MM-DD').format(_getFormatter()),
      t: moment(el.date, 'YYYY-MM-DD').toDate().getTime()
    }
  });
  d = d.sort((a, b) => a.t - b.t);
  // group data now
  d = _.groupBy(d, _t => _t.x)
  // console.log(d);
  d = Object.keys(d).map((k) => {
    let v = d[k];
    // console.log(v, k);
    return {
      x: k,
      _x: k,
      y: v.reduce((a, b) => {
        return a + b.y
      }, 0)
    }
  });
  // console.log(d);
  return d;
}

export const checkIsValidToPublish = (data, isQuizType) => {
  const {
    quiz_question_quiz_deck_by_question_id = {}
  } = data || {};
  let isCorrectExist = false;
  if (quiz_question_quiz_deck_by_question_id.quiz_question_type === 'QuizOptionQuestion') {
    const {
      options = '[]'
    } = quiz_question_quiz_deck_by_question_id.quiz_question_data || {};
    const optionsJson = JSON.parse(options);
    if (Array.isArray(optionsJson)) {
      const selectedOption = optionsJson.find(el => {
        if (el.correct === true) {
          return el;
        }
      });
      if (selectedOption) {
        isCorrectExist = true;
      }
    }
  } else {
    isCorrectExist = true;
  }
  return isCorrectExist;
}


export const getDatesFromDateByNumber = (fromDate, numberOfDays = 0) => {
  let datesList = [];

  Array.from({ length: numberOfDays }).map((el, idx) => {
    let nextDate = fromDate ? new Date(fromDate): new Date();
    nextDate.setDate(nextDate.getDate() + idx)
    const nextDateStr = `${nextDate.getMonth() + 1}/${nextDate.getDate()}/${nextDate.getFullYear()}`;
    datesList.push(nextDateStr);
  })
  return datesList;
}