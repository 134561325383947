import React, { useEffect, useMemo, useState } from "react";
import { useLazyQuery } from "@apollo/client";
import { useStyletron } from "baseui";
import { Drawer } from "baseui/drawer";
import { HeadingSmall, ParagraphMedium } from "baseui/typography";
import { client } from "../../apollo/client";
import { template_tags_integration_group_by_tag, template_tags_org } from "../templates/queries";
import { Checkbox, LABEL_PLACEMENT } from "baseui/checkbox";
import { Spinner } from "baseui/spinner";
import { ModalButton } from "baseui/modal";

const drawerOverrides = {
  Root: {
    style: {
      zIndex: 1
    }
  },
  DrawerBody: {
    style: ({ $theme }) => ({
      marginLeft: '20px',
    })
  },
};

const AddNewDefaultTemplateModal = ({
  isEdit,
  isVisible,
  onClose = () => { },
  organization,
  onSelect,
  selectedTemplateTags = [],
  ...props
}) => {

  const [tags, setTags] = useState([]);
  const [loading, setLoading] = useState(false);
  const [selectedTags, setSelectedTags] = useState([]);
  const [isTemplatesAdding, setIsTemplatesAdding] = useState(false);
  const [css] = useStyletron();

  // const [fetchTemplateTagIntegration, { data: templateTagIntegration, loading: templateTagIntegrationLoading, refetch: refetchTemplateTagInteg }] = useLazyQuery(template_tags_integration_group_by_tag,
  //   {
  //     variables: {
  //       organization_id: organization.id
  //     },
  //     fetchPolicy: 'network-only'
  //   }
  // );

  useEffect(() => {
    setSelectedTags(selectedTemplateTags)
  }, [selectedTemplateTags])

  const loadTags = () => {
    setLoading(true);
    client.query({
      query: template_tags_org(organization.id),
      fetchPolicy: 'network-only'
    }).then(({ data }) => {

      setTags([...data.template_tags_org].sort((a, b) => a.position - b.position));
    }).catch((err) => {
      console.log('loadTags error ', err)
    }).finally(() => {
      setLoading(false);
    })
  };

  useEffect(() => {
    if (isVisible) {
      loadTags();
      // fetchTemplateTagIntegration();
    }
  }, [isVisible])


  const onCloseModal = () => {
    onClose();
  }


  const onSelectAllPress = () => {
    if (selectedTags.length === tags.length) {
      setSelectedTags([]);
      return;
    }
    setSelectedTags(tags);
  }

  const onAddClick = async () => {
    try {
      setIsTemplatesAdding(true)
      const created = await onSelect(selectedTags);
      setIsTemplatesAdding(false);
      onCloseModal();
    } catch (err) {
      setIsTemplatesAdding(false)
    }
  }

  ///////////////////////////////////////////////////////// Render Functions ///////////////////////////////////////////////

  const renderTagItem = (tag, index) => {
    const isSelected = selectedTags.find(el => el.id === tag.id);
    const onSelectTag = () => {
      if (!isSelected) {
        setSelectedTags([
          ...selectedTags,
          tag
        ])
      } else {
        setSelectedTags(selectedTags.filter(el => el.id !== tag.id));
      }
    }
    return (
      <Checkbox
        checked={isSelected}
        onChange={onSelectTag}
        labelPlacement={LABEL_PLACEMENT.right}
      >
        <ParagraphMedium marginBottom='10px' paddingRight='14px'>{tag.name}</ParagraphMedium>
      </Checkbox>
    )
  }

  const TemplatesList = useMemo(() => {
    let isIndeterminate = selectedTags.length > 0 && selectedTags.length < tags.length;
    let allChecked = selectedTags.length === tags.length;
    return (
      <div
        className={css({
          flex: 1,
        })}
      >
        {
          tags.length > 1 && (
            <div
              className={css({
                width: '100%',
              })}
            >
              <Checkbox
                isIndeterminate={isIndeterminate}
                checked={allChecked}
                onChange={onSelectAllPress}
              >
                Select All Template Tags
              </Checkbox>
            </div>
          )
        }

        <div
          className={css({
            padding: '20px',
          })}
        >
          {tags.map(renderTagItem)}
        </div>

      </div>
    )
  }, [tags, selectedTags]);

  return (
    <Drawer
      isOpen={isVisible}
      autoFocus
      onClose={onCloseModal}
      overrides={drawerOverrides}
    >
      <HeadingSmall
        style={{
          textAlign: 'center'
        }}
      >
        Select Template tags
      </HeadingSmall>
      {
        loading && (
          <div
            className={css({
              display: 'flex',
              width: '100%',
              justifyContent: 'center',
              alignContent: 'center',
              paddingBottom: '10px'
            })}
          >
            <Spinner
              size={40}
              color='black'
            />
          </div>
        )
      }
      {TemplatesList}
      <div
        style={{
          display: 'flex',
          position: 'absolute',
          minHeight: '50px',
          paddingBottom: '10px',
          paddingRight: '20px',
          paddingLeft: '20px',
          bottom: 0,
          right: 0,
          width: '100%',
          flexDirection: 'column'
        }}

      >
        <div
          className={css({
            justifyContent: 'flex-end',
            display: 'flex'
          })}
        >
          <ModalButton
            onClick={onCloseModal}
            isLoading={isTemplatesAdding}
          >
            Cancel
          </ModalButton>

          <ModalButton
            isLoading={isTemplatesAdding}
            onClick={onAddClick}
          >
            {isEdit ? 'Update' : 'Add'}
          </ModalButton>
        </div>
      </div>
    </Drawer>
  )
}

export default AddNewDefaultTemplateModal;