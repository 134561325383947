import * as React from 'react';
import { connect } from 'react-redux';
import Settings from './settings';
import { Skeleton } from 'baseui/skeleton'
import { useQuery, useMutation } from '@apollo/client';
import RightSidePageHeader from '../pageHeaders';

import gql from 'graphql-tag';
import { useStyletron } from 'baseui';

const SettingsWrapper = ({
  organization = {},
  organization_settings = {},
  organization_id,
  dispatch,
  refetch,
  toaster
}) => {
  const [css, theme] = useStyletron();
  const [isLoading, setIsLoading] = React.useState(true);

  const LoadingBar = () => {
    const TAB_STATUS = {
      GENERAL: 'General / Appearance',
    };
    const pageTitlesAndDescriptions = {
      [TAB_STATUS.GENERAL]: {
        title: 'General Settings',
        description: 'Manage basic settings of organization'
      },
    }
    return (
      <div style={{ width: '100%', }}>
        <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
          <div>
            <RightSidePageHeader
              icon={null}
              title={pageTitlesAndDescriptions[TAB_STATUS.GENERAL].title}
              headerDescription={pageTitlesAndDescriptions[TAB_STATUS.GENERAL].description}
            />
          </div>
          <Skeleton
            width="500px"
            height="35px"
            animation
            overrides={{
              Root: {
                style: {
                  marginRight: '60px',
                  marginTop: '1rem'
                },
              },
            }}
          />
        </div>
        <div style={{ marginLeft: '2rem', marginTop: '1rem' }}>
          {new Array(5).fill('').map(item => {
            return (
              <div style={{ display: 'flex', flexDirection: 'row', marginTop: '2rem' }}>
                {new Array(3).fill('').map(item => <Skeleton
                  width="500px"
                  height="145px"
                  animation
                  overrides={{
                    Root: {
                      style: {
                        marginRight: '60px'
                      },
                    },
                  }}
                />)}
              </div>
            )
          })}
        </div>
      </div>
    )
  }

  React.useEffect(() => {
    setTimeout(() => {
      setIsLoading(false);
    }, 1500)
  })

  if (isLoading || !organization) {
    return <LoadingBar />
  }

  return (
    <Settings
      organization_id={organization_id}
      dispatch={dispatch}
      organization={organization}
      organization_settings={organization_settings}
      integrations={[]}
      specifications={[]}
      refetch={refetch}
      toaster={toaster}
    ></Settings>
  );
};

const mapStateToProps = props => {
  let { organization, organization_settings } = props.appBasicControls;
  return {
    organization, organization_settings
  };
};
export default connect(mapStateToProps)(SettingsWrapper);
