import gql from "graphql-tag";

export const sharepoint_form_templates = gql`
    query sharepoint_form_templates($organization_id: ID) {
        sharepoint_form_templates(organization_id: $organization_id) {
            id
            title
            description
            sharepoint_spreadsheet_id
            organization_id
            schema
            is_single_form
            form_type
            show_on_home_screen
            created_at
            updated_at
            cover_image_data
            dialog_open_button_text
            is_template
            can_multiple_response
            is_reference_form
            parent_id
            tags {
                id
                name
            }
        }
    }

`;

export const createSharePointFormModuleTemplate = gql`
    mutation createSharePointFormModuleTemplate(
        $organization_id: ID
        $position: Int
        $cover_image: Upload
        $title: String
        $description: String
        $schemaArr: [schemaInput]
        $form_type: String
        $show_on_home_screen: Boolean
        $dialog_open_button_text: String
        $tag_ids: [ID]
        $add_form_child_components: Boolean
        $publish_child_components: Boolean
    ) {
        createSharePointFormModuleTemplate(
            organization_id: $organization_id
            position: $position
            cover_image: $cover_image
            title: $title
            description: $description
            schemaArr: $schemaArr
            form_type: $form_type
            show_on_home_screen: $show_on_home_screen
            dialog_open_button_text: $dialog_open_button_text
            tag_ids: $tag_ids
            add_form_child_components: $add_form_child_components
            publish_child_components: $publish_child_components
        ) {
            id
            sharepoint_spreadsheet_id
            is_template
            parent_id
            cover_image_data
            tags {
                id
                name
            }
            __typename
        }
    }

`;

export const updateSharePointFormModuleTemplate = gql`
    mutation updateSharePointFormModuleTemplate(
    $id: ID
    $title: String
    $description: String
    $schemaArr: [schemaInput]
    $form_type: String
    $show_on_home_screen: Boolean
    $cover_image: Upload
    $dialog_open_button_text: String
    $tag_ids: [ID]
    $is_archived: Boolean
    $can_multiple_response: Boolean
    $is_reference_form: Boolean
    ) {
        updateSharePointFormModuleTemplate(
            id: $id
            cover_image: $cover_image
            title: $title
            description: $description
            schemaArr: $schemaArr
            form_type: $form_type
            show_on_home_screen: $show_on_home_screen
            dialog_open_button_text: $dialog_open_button_text
            tag_ids: $tag_ids
            is_archived: $is_archived
            can_multiple_response: $can_multiple_response
            is_reference_form: $is_reference_form
        ) {
            id
            title
            sharepoint_spreadsheet_id
            is_template
            parent_id
            cover_image_data
            can_multiple_response
            is_reference_form
            tags {
                id
                name
            }
            __typename
        }
    }

`;

export const moveFormTemplateToHomeComponent = gql`
    mutation moveFormTemplateToHomeComponent (
        $sharepoint_form_id: ID,
        $home_component_id: ID
    ) {
        moveFormTemplateToHomeComponent (
            sharepoint_form_id: $sharepoint_form_id
                home_component_id: $home_component_id
        ) {
            id
            sharepoint_spreadsheet_id
            is_template
            parent_id
            cover_image_data
            __typename
        }
    }
`;

export const importSharePointFormModulesByTags = gql`
    mutation importSharePointFormModulesByTags(
        $tag_ids: [ID]
        $organization_id: ID
        $position: Int
        $channel_id: ID
        $published: Boolean
    ) {
        importSharePointFormModulesByTags(
            tag_ids: $tag_ids
            organization_id: $organization_id
            position: $position
            channel_id: $channel_id
            published: $published
        ) {
            status
	        message
	        request_id
        }
    }

`;

export const importSharePointFormModulesByTagsSubscription = gql`
    subscription importSharePointFormModulesByTags($request_id: String) {
        importSharePointFormModulesByTags(request_id: $request_id) {
            is_done
            is_error
            error
            result
            request_id
            total
            current
        }
    }
`;

export const template_tags_org = (organization_id) => gql`
  query template_tags_org {
    template_tags_org(
      organization_id: "${organization_id}"
      ) {
        id
        name
        cover_image
        position
        metadata
        archived
        form_report_type
    }
  }
`;

export const createTemplateTag = gql`
  mutation createTemplateTag ($name: String, $organization_id: ID!, $position: Int, $cover_image: Upload) {
    createTemplateTag(name: $name, position: $position, organization_id: $organization_id, cover_image: $cover_image)
   {
        id
        name
        organization_id
        cover_image
        position
        archived
        form_report_type
    }
  }
`;

export const updateTemplateTag = gql`
  mutation updateTemplateTag ($id: ID!, $name: String, $position: Int, $isDeleted: Boolean, $cover_image: Upload, $metadata: String, $form_report_type: String) {
    updateTemplateTag(id: $id, name: $name, position: $position, isDeleted: $isDeleted, cover_image: $cover_image, metadata: $metadata, form_report_type: $form_report_type)
   {
        id
        name
        cover_image
        position
        metadata
        archived
        form_report_type
    }
  }
`;

export const archiveTemplateTag = gql`
    mutation archiveTemplateTag ($id: ID!, $archived: Boolean) {
        archiveTemplateTag(id: $id, archived: $archived) {
            id
            name
            cover_image
            position
            metadata
            archived
            form_report_type
        }
    }
`

export const deleteFormTemplateByTag = gql`
    mutation deleteFormTemplateByTag($tag_id: ID, $form_id: ID, $delete_child_forms: Boolean) {
        deleteFormTemplateByTag(tag_id: $tag_id, form_id: $form_id, delete_child_forms: $delete_child_forms)
    }
`

export const template_tags_integration_group_by_tag = gql`
    query template_tags_integration_group_by_tag ($organization_id: ID) {
    template_tags_integration_group_by_tag (organization_id: $organization_id) {
        tag_id
        templates {
            id
            template_id
            template_type
            is_archived
            published
            position
        }
    }
}
`

export const archiveTemplateTagIntegration = gql`
    mutation archiveTemplateTagIntegration ($input : ArchiveTemplateTagIntegrationUpdateInput) {
        archiveTemplateTagIntegration (input: $input) {
            id
            template_id
            template_type
            is_archived
            position
        }
    }
`

export const updateTemplateTagIntegrationPositions = gql`
    mutation updateTemplateTagIntegrationPositions($input: [updateTemplateTagIntegrationPosition]) {
        updateTemplateTagIntegrationPositions (input: $input) {
            id
            template_id
            template_type
            is_archived
            position
        }
    }
`

export const publishTemplateTagIntegration = gql`
    mutation publishTemplateTagIntegration ($input : PublishedTemplateTagIntegrationUpdateInput) {
        publishTemplateTagIntegration (input: $input) {
            id
            template_id
            template_type
            is_archived
            position
        }
    }
`

export const importSharepointFormTagTemplatesMultipleChannels =  gql`
    mutation  importSharepointFormTagTemplatesMultipleChannels (
		$tag_ids: [ID]
		$organization_id: ID
		$position: Int
		$channel_ids: [ID]
		$published: Boolean
    ) {
        importSharepointFormTagTemplatesMultipleChannels (
                tag_ids: $tag_ids
                organization_id: $organization_id
                position: $position
                channel_ids: $channel_ids
                published: $published
        ){
        status
        message
        request_id
        error
        }
  }
`

export const importSharepointFormTagTemplatesMultipleChannelsSub = gql`
    subscription importSharepointFormTagTemplatesMultipleChannels($request_id: String) {
        importSharepointFormTagTemplatesMultipleChannels(request_id: $request_id) {
            is_done
            is_error
            error
            result
            request_id
            total
            current
        }
    }

`

export const app_content_scope_imported_template_tags = gql`
    query app_content_scope_imported_template_tags($organization_id: ID!) {
        app_content_scope_imported_template_tags(organization_id: $organization_id) {
            id
            name
            organization_id
            position
            logo_data
            icon_data
            updated_at
            report_status
            template_tags {
                id
                name
            }
        }
    }
`

export const import_progress_trackers_by_id = gql`
    query import_progress_trackers_by_id ($id: ID) {
        import_progress_trackers_by_id(id: $id) {
            id
            organization_id
            channel_id
            total_task
            current_task
            is_done
            is_error
            error
            result
        }
    }
`