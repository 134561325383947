import * as React from 'react';
import {
    Modal,
    ModalBody,
    ModalFooter,
    ModalButton,
    SIZE,
    ROLE,
} from 'baseui/modal';
// import { KIND as ButtonKind } from 'baseui/button';
import {
    LabelMedium
} from 'baseui/typography';
// import { Input } from 'baseui/input';
import { getTimeRange } from '../dashboard/utils';
import { DatePicker } from "baseui/datepicker";
import moment from 'moment';

const dateOverrides = {
    Popover: {
        props: {
            overrides: {
                Body: { style: { zIndex: 3 } },
            },
        },
    },
    Input: {
        props: {
            overrides: {
                Input: {
                    style: ({ $theme }) => ({
                        fontStyle: "normal",
                        fontWeight: "normal",
                        fontSize: "16px",
                        lineHeight: "24px",
                        paddingTop: '10px',
                        paddingBottom: '10px'
                    })
                }
            }
        }
    }
}

const DateRangeSelectorModal = ({
    modalText,
    isOpen,
    onClose = () => { },
    btnText,
    onButtonClick = () => { }
}) => {

    const [startDate, setStartDate] = React.useState(
        moment().toDate().toISOString()
    );

    const [endDate, setEndDate] = React.useState(
        (getTimeRange().end_time)
    );

    const onClickSave = () => {
        onButtonClick(startDate, endDate);
    }

    const onModalClose = () => {

        onClose()
    }

    return (
        <Modal
            onClose={onModalClose}
            closeable
            isOpen={isOpen}
            animate
            autoFocus
            size={SIZE.default}
            role={ROLE.dialog}
            overrides={{
                Dialog: {
                    style: {
                        width: '70vw',
                        display: 'flex',
                        flexDirection: 'column',
                    },
                },
                Backdrop: {
                    style: ({ $theme }) => ({
                        backdropFilter: $theme.modalBackDropFilter,
                    }),
                },
                Root: {
                    style: {
                        zIndex: 3
                    }
                }
            }}
        >
            <ModalBody>
                <LabelMedium style={{
                    marginTop: '32px'
                }}>
                    {modalText || 'Please select date range from below'}
                </LabelMedium>
                <div style={{ display: 'flex', flexDirection: 'row' }}>
                    <div style={{ display: 'flex', flexDirection: 'column',  marginRight: '4px' }}>
                        <LabelMedium style={{
                            marginTop: '27px',
                            marginBottom: '15px'
                        }}>
                            Start Date
                        </LabelMedium>
                        <DatePicker
                            value={new Date(startDate)}
                            onChange={({ date }) => {
                                if (date > new Date(endDate)) {
                                    setEndDate(date.toISOString())
                                }
                                setStartDate(date.toISOString())
                            }}
                            size={SIZE.mini}
                            formatString="MM/dd/yyyy"
                            placeholder="MM/dd/yyyy"
                            overrides={dateOverrides}
                            clearable
                        />
                    </div>

                    <div style={{ display: 'flex',  flexDirection: 'row', alignItems: 'flex-end', marginLeft: '14px', marginRight: '14px', justifyContent: 'center', fontSize: '14px', paddingBottom: '14px' }}>
                        TO
                    </div>

                    <div style={{ display: 'flex',  flexDirection: 'column', marginRight: '16px' }}>
                        <LabelMedium style={{
                            marginTop: '27px',
                            marginBottom: '15px'
                        }}>
                            End Date
                        </LabelMedium>
                        <DatePicker
                            value={new Date(endDate)}
                            onChange={({ date }) => setEndDate(date.toISOString())}
                            size={SIZE.mini}
                            formatString="MM/dd/yyyy"
                            placeholder="MM/dd/yyyy"
                            overrides={dateOverrides}
                            minDate={new Date(startDate)}
                            clearable
                        />
                    </div>
                </div>
            </ModalBody>
            <ModalFooter>
                <ModalButton
                    onClick={onClickSave}

                    style={{ textTransform: 'capitalize' }}
                >
                    {btnText || 'DOWNLOAD'}
                </ModalButton>
            </ModalFooter>
        </Modal >
    )
}

export default DateRangeSelectorModal;