import CardLinkDeckIcon from './card_link_deck_icon.svg';
import DashboardDeckIcon from './dashboard_deck_icon.svg';
import ImageDeckIcon from './image_deck_icon.svg';
import InsertLinkDeckIcon from './insert_link_deck.svg';
import PhoneLinkDeckIcon from './phone_link_deck_icon.svg';
import SliderDeckIcon from './slider_deck_icon.svg';
import TableDeckIcon from './table_deck_icon.svg';
import TextIcon from './text_icon.svg';
import MediaIcon from './media_icon.svg';
import ButtonsIcon from './buttons_icon.svg';
import AdvancedIcon from './advanced_icon.svg';
import MoreIcon from './more_icon.svg';
import TextBoxIcon from './text_box_icon.svg';
import BlackTextBoxIcon from './black_text_box_icon.svg';
import BlackSingleImageIcon from './black_single_image_icon.svg';
import BlackImageGalleryIcon from './black_image_gallery_icon.svg';
import BlackHeaderImageIcon from './black_header_image_icon.svg';
import BlackSingleVideoIcon from './black_single_video_icon.svg';
import BlackVideoPlaylistIcon from './black_video_playlist_icon.svg';
import BlackSingleAudioIcon from './black_single_audio_icon.svg';
import BlackAudioPlaylistIcon from './black_audioplaylist_icon.svg';
import BlackAttachmentIcon from './black_attachment_icon.svg';
import BlackCardIcon from './black_card_icon.svg';
import BlackWebsiteIcon from './black_website_icon.svg';
import BlackMeetingIcon from './black_meeting_icon.svg';
import BlackPhoneIcon from './black_phone_icon.svg';
import BlackGoogleSheetsIcon from './black_google_sheets_icon.svg';
import BlackEmbedIcon from './black_embed_icon.svg';
import BlackBusinessCardIcon from './black_business_card_icon.svg';
import BlackFullscreenWebCardIcon from './black_full_screen_web_card_icon.svg';
import BlackSingleUseButtonIcon from  './black_single_use_button_icon.svg';
import BlackDashboardIcon from  './black_dashboard_icon.svg';
import CopyCardIcon from  './copy_card_icon.svg';
import ActiveIcon from './active.svg';
import UsersIcon from './users.svg';
import InvitationIcon from './email_sent.svg';
import DownloadIcon from './download.svg';
import AddIcon from './add.svg';
import SwitchAccess from './switch_access_shortcut_add_FILL0_wght400_GRAD0_opsz48.svg';
import SwitchAccessWhite from './switch_access_white.svg';
import TrackingIcon from './tracking_icon.svg';

const svgIcons = {
    CardLinkDeckIcon,
    DashboardDeckIcon,
    ImageDeckIcon,
    InsertLinkDeckIcon,
    PhoneLinkDeckIcon,
    SliderDeckIcon,
    TableDeckIcon,
    TextIcon,
    MediaIcon,
    ButtonsIcon,
    AdvancedIcon,
    ActiveIcon,
    UsersIcon,
    InvitationIcon,
    MoreIcon,
    TextBoxIcon,
    BlackTextBoxIcon,
    BlackSingleImageIcon,
    BlackImageGalleryIcon,
    BlackHeaderImageIcon,
    BlackSingleVideoIcon,
    BlackVideoPlaylistIcon,
    BlackSingleAudioIcon,
    BlackAudioPlaylistIcon,
    BlackAttachmentIcon,
    BlackCardIcon,
    BlackWebsiteIcon,
    BlackMeetingIcon,
    BlackPhoneIcon,
    BlackGoogleSheetsIcon,
    BlackEmbedIcon,
    BlackBusinessCardIcon,
    BlackFullscreenWebCardIcon,
    BlackSingleUseButtonIcon,
    BlackDashboardIcon,
    CopyCardIcon,
    DownloadIcon,
    AddIcon,
    SwitchAccess,
    SwitchAccessWhite,
    TrackingIcon
};

export default svgIcons;