import * as React from 'react';
import { Grid, Cell } from 'baseui/layout-grid';
import { useStyletron } from 'baseui';
import {
  LabelSmall, ParagraphSmall
} from 'baseui/typography';
import { Skeleton } from 'baseui/skeleton'
import Button from '../styledButton';
import { gridPaddingOverrides, cellPaddingOverrides } from '../overrides';
import { client } from '../../apollo/client';
import { APPENV_QUERY as AppEnvQuery, UPDATE_THEME } from './appenv-options';
import JSONInput from 'react-json-editor-ajrm';
import locale from 'react-json-editor-ajrm/locale/en';
import { Accordion, Panel } from "baseui/accordion";
import { Drawer } from "baseui/drawer";
import moment from "moment";
import _ from 'lodash';


const AppTheme = () => {
  const [loading, setLoading] = React.useState(false);
  const [onSaveLoading, setOnSaveLoading] = React.useState(false);
  const [_theme, setTheme] = React.useState({});
  const [serverTheme, setServerTheme] = React.useState({});
  const [_ImageTheme, setImageTheme] = React.useState({});
  const [ENV_NAME, SET_ENV_NAME] = React.useState(null);
  const [error, setError] = React.useState('');
  const [versions, setVersions] = React.useState([]);
  const [isOpen, setIsOpen] = React.useState(false);

  const fetchData = () => {
    setLoading(true)
    client.query({ query: AppEnvQuery, fetchPolicy: 'network-only' })
      .then((data) => {
        if (data.data.organization_app_env_variable_org === null) {
          setError('Please define env first from app environment settings');
          setLoading(false)
          return;
        };
        SET_ENV_NAME(data.data.organization_app_env_variable_org.ENV_NAME);
        if (data.data.organization_app_env_variable_org && data.data.organization_app_env_variable_org._theme) {
          try {
            setTheme(JSON.parse(data.data.organization_app_env_variable_org._theme));
            setServerTheme(JSON.parse(data.data.organization_app_env_variable_org._theme));
          } catch {

          }
        }
        if (data.data.organization_app_env_variable_org && data.data.organization_app_env_variable_org._image_theme) {
          try {
            setImageTheme(JSON.parse(data.data.organization_app_env_variable_org._image_theme));
          } catch {
          }
        };

        if (data.data.organization_app_env_variable_org && data.data.organization_app_env_variable_org._theme_versions) {
          try {
            setVersions(JSON.parse(data.data.organization_app_env_variable_org._theme_versions));
          } catch {

          }
        }
        setLoading(false)
      }).catch(err => {
        setLoading(false)
        console.log(err);
      })
  };

  const setThemeInternal = (type, data) => {
    console.log(type, data);
    let t = JSON.parse(JSON.stringify(_theme));
    t[type] = data;
    setTheme(t);
  };

  console.log(_theme);
  console.log(_ImageTheme);
  console.log(versions);

  React.useEffect(() => {
    fetchData();
  }, []);

  const onSave = () => {
    setError(null);
    setOnSaveLoading(true);
    Promise.all(
      [
        ... !isEqual ? [client.mutate({
          mutation: UPDATE_THEME,
          variables: {
            ENV_NAME: ENV_NAME,
            theme: JSON.stringify(_theme)
          }
        })] : [],
        client.mutate({
          mutation: UPDATE_THEME,
          variables: {
            ENV_NAME: "image",
            theme: JSON.stringify(_ImageTheme)
          }
        })
      ]
    )
      .then(() => {
        // console.log(d1);
        setOnSaveLoading(false);
        fetchData();
      }).catch(err => {
        setError(err.message);
        setOnSaveLoading(false);
      });
  };

  const getUserInfo = (item) => {
    try {
      let t = JSON.parse(item.created_by_info);
      return t.name ? `${t.name}(${t.email})` : ``;
    } catch (err) {
      return '';
    }
  };

  const loadVersion = (item) => {
    try {
      setTheme(JSON.parse(item.theme))
    } catch (err) {

    };
    setIsOpen(false);
  };

  const isEqual = _.isEqual(_theme, serverTheme);
  console.log('isEqualisEqual', isEqual);


  if (loading) {
    return (
      <div style={{ width: '100%', }}>
        <div style={{marginLeft: '2rem', marginTop: '1rem'}}>
          {new Array(10).fill('').map(item => {
            return (
              <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'center', marginTop: '2rem'}}>
                {new Array(2).fill('').map(item => <Skeleton
                  width="48%"
                  height="80px"
                  animation
                  overrides={{
                      Root: {
                          style: {
                            marginRight: '60px'
                          },
                      },
                  }}
                />)}
              </div>
            )
          })}
      </div>
    </div>
    );
  };


  return <>
    <Grid overrides={gridPaddingOverrides}>
      <Cell overrides={cellPaddingOverrides} span={12}>
        <div style={{ position: 'relative', float: 'right' }}>
          <Button isSelected isLoading={onSaveLoading} style={{ marginLeft: '16px' }} onClick={() => onSave()}>Save</Button>
        </div>
        {versions.length > 0 && <div style={{ position: 'relative', float: 'right' }}>
          <Button isSelected style={{ marginLeft: '16px' }} onClick={() => setIsOpen(true)}>Versions</Button>
        </div>}
        {!isEqual && <div style={{ position: 'relative', float: 'right' }}>
          <Button isSelected style={{ marginLeft: '16px' }} onClick={() => {
            setTheme(serverTheme);
          }}>Rollback to Original</Button>
        </div>}
        <div style={{ position: 'relative', float: 'right', marginTop: '22px' }}>
          {error && <LabelSmall style={{ color: 'red' }}>{error}</LabelSmall>}
        </div>
      </Cell>
    </Grid>

    <Grid overrides={{
      Grid: {
        style: () => ({
          padding: '0px !important',
          marginTop: '16px'
        }),
      },
    }}>
      <Accordion overrides={{
        Root: {
          style: ({ $theme }) => ({
            marginLeft: 0,
          })
        }
      }}>
        <Panel title="App Specifics">
          <JSONInput
            id='a_unique_id'
            placeholder={_theme.appSpecific || {}}
            theme="dark_vscode_tribute"
            colors={{
              string: "#DAA520" // overrides theme colors with whatever color value you want
            }}
            locale={locale}
            height='550px'
            width={'60vw'}
            onChange={e => {
              // console.log(e);
              !e.error && setThemeInternal('appSpecific', e.jsObject);
            }}
          />
        </Panel>
        <Panel title="Color">
          <JSONInput
            id='a_unique_id'
            placeholder={_theme.color || {}}
            theme="dark_vscode_tribute"
            colors={{
              string: "#DAA520" // overrides theme colors with whatever color value you want
            }}
            locale={locale}
            height='550px'
            width={'60vw'}
            onChange={e => {
              // console.log(e);
              !e.error && setThemeInternal('color', e.jsObject);
            }}
          />
        </Panel>
        <Panel title="Fonts">
          <JSONInput
            id='a_unique_id'
            placeholder={_theme.fonts || {}}
            theme="dark_vscode_tribute"
            colors={{
              string: "#DAA520" // overrides theme colors with whatever color value you want
            }}
            locale={locale}
            height='550px'
            width={'60vw'}
            onChange={e => {
              // console.log(e);
              !e.error && setThemeInternal('fonts', e.jsObject);
            }}
          />
        </Panel>
        <Panel title="Image size configuration">
          <JSONInput
            id='aa_unique_id'
            placeholder={_ImageTheme}
            theme="dark_vscode_tribute"
            colors={{
              string: "#DAA520" // overrides theme colors with whatever color value you want
            }}
            locale={locale}
            height='550px'
            width={'60vw'}
            onChange={e => {
              // console.log(e);
              !e.error && setImageTheme(e.jsObject);
            }}
          />
        </Panel>
      </Accordion>
    </Grid>
    <Drawer
      isOpen={isOpen}
      autoFocus
      onClose={() => setIsOpen(false)}
      overrides={{
        Root: {
          style: {
            zIndex: 2
          }
        }
      }}
    >
      {versions.sort((a, b) => {
        return new Date(b.created_at).getTime() - new Date(a.created_at).getTime();
      }).map((el, index) => {
        return <div key={index} style={{
          position: 'relative',
          width: '100%',
          background: 'white',
          marginBottom: '16px'
        }}>
          <LabelSmall>Version ID: {el.id.substr(el.id.length - 6).toUpperCase()}</LabelSmall>
          <div style={{ display: 'flex', marginTop: '8px' }}>
            <ParagraphSmall style={{ marginTop: '6px', marginLeft: '4px', width: '50%', marginRight: '4px', paddingRight: '4px', borderRight: '2px solid #E2E2E2' }}>
              Updated at {moment(el.created_at).format('llll')}
            </ParagraphSmall>
            <ParagraphSmall style={{ marginTop: '6px', marginLeft: '4px' }}>
              Version Created By {getUserInfo(el)}
            </ParagraphSmall>
          </div>
          <div style={{ marginBottom: '16px' }}>
            <Button isSelected style={{ marginLeft: '0px', marginTop: '0px' }} onClick={() => loadVersion(el)}>Load Version</Button>
          </div>
          <hr style={{ height: '2px', margin: 0, marginTop: '0px', background: '#E2E2E2' }} />
        </div>
      })}
    </Drawer>
  </>
};

export default AppTheme;