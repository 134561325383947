import * as React from 'react';
import { Grid, Cell } from 'baseui/layout-grid';
import { gridPaddingOverrides, cellPaddingOverrides } from '../overrides';
import Button from '../styledButton';
import { client, getServerUrl } from '../../apollo/client';
import { Accordion, Panel } from "baseui/accordion";
import gql from 'graphql-tag';
import { Input } from 'baseui/input';
import {
  ParagraphSmall,
  ParagraphXSmall,
  LabelSmall,
  LabelMedium
} from 'baseui/typography';
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  ModalButton,
} from 'baseui/modal';
import { FileUploader } from "baseui/file-uploader";
import ImageUploadCXL from '../imageUploaderCXL';
import axios from 'axios';

const WorkingDaysModal = ({ working_days, onChange }) => {
  const [isOpen, setIsOpen] = React.useState(false);
  const [data, setData] = React.useState([]);
  React.useEffect(() => {
    try {
      working_days = JSON.parse(working_days);
      let d = [1, 2, 3, 4, 5, 6, 7].map((el, i) => {
        return {
          day: working_days[i] ? working_days[i].day : '',
          time: working_days[i] ? working_days[i].time : ''
        }
      })
      setData(d);
    } catch (e) {
      let d = [1, 2, 3, 4, 5, 6, 7].map((el, i) => {
        return {
          day: '',
          time: ''
        }
      })
      setData(d);
    }
  }, [working_days]);

  const _onChange = (value, index, key) => {
    let d = [...data];
    d[index][key] = value;
    setData(d);
  };

  const onSave = () => {
    // console.log(JSON.stringify(data));
    onChange('working_days', JSON.stringify(data));
    setIsOpen(false);
  };

  console.log(data);
  if (data.length == 0) return null;

  return <div style={{ marginTop: '0px' }}>
    <Button isSelected onClick={() => {
      setIsOpen(true);
    }}>Edit Working Days</Button>
    <Modal
      isOpen={isOpen}
      onClose={() => {
        setIsOpen(false);
      }}
    >
      <ModalHeader>{'Add & Edit Working Days'}</ModalHeader>
      <ModalBody style={{ flex: '1 1 0' }}>
        <Grid overrides={gridPaddingOverrides}>
          {/* <Cell overrides={cellPaddingOverrides} span={12}>
            <div style={{ position: 'relative', float: 'right' }}>
              <Button onClick={() => {
                addNew();
              }} isSelected>Add New</Button>
            </div>
          </Cell> */}
          <Cell overrides={cellPaddingOverrides} span={12}>
            {[1, 2, 3, 4, 5, 6, 7].map((el, i) => {
              return <div style={{
                display: 'flex',
                marginBottom: '12px'
              }}>
                <Input
                  value={data[i].day}
                  onChange={(e) => {
                    _onChange(e.target.value, i, 'day');
                  }}
                  placeholder={"Day"}
                />
                <div style={{
                  paddingLeft: '12px'
                }}>
                  <Input
                    value={data[i].time}
                    onChange={(e) => {
                      _onChange(e.target.value, i, 'time');
                    }}
                    placeholder={"Time"}
                  />
                </div>
              </div>
            })}
          </Cell>
        </Grid>
      </ModalBody>
      <ModalFooter>
        <ModalButton
          onClick={() => {
            onSave();
          }}
        >
          {'Save'}
        </ModalButton>
      </ModalFooter>
    </Modal>
  </div>
};


const Location = ({ index, setLocations, ...props }) => {
  const [isLoading, setIsLoading] = React.useState(false);
  const [location, setLocation] = React.useState(props.location);
  console.log(location);
  const OPTIONS = [
    {
      key: 'name',
      display: 'Name',

    },
    {
      key: 'description',
      display: 'Description',

    },
    {
      key: 'addr',
      display: 'Address',

    },
    {
      key: 'state',
      display: 'State',

    },
    {
      key: 'city',
      display: 'City',

    },
    {
      key: 'country',
      display: 'Country',

    },
    {
      key: 'pincode',
      display: 'Pincode',

    },
    {
      key: 'latitude',
      display: 'Latitude',

    },
    {
      key: 'longitude',
      display: 'Longitude',

    },
    {
      key: 'phone',
      display: 'Phone',

    },
    {
      key: 'email',
      display: 'Email',

    },
    {
      key: 'facebook',
      display: 'Facebook',

    },
    {
      key: 'twitter', // key is getting used as instagram
      display: 'Instagram',

    },
    {
      key: 'home_gym_mapping',
      display: 'Home Gym Id'
    }
  ];

  const onSave = async () => {
    setIsLoading(true);
    await client.mutate({
      mutation: gql`
        mutation updateOrganizationLocations($working_days: String $image: Upload) {
          updateOrganizationLocations(
            id: "${location.id}"
            working_days: $working_days
            image: $image
            ${OPTIONS.map(op => {
        return `${op.key}: "${location[op.key]}" \n`;
      })}
          ) {
            id
            name
            latitude
            working_days
          }
        }
      `,
      variables: {
        working_days: location.working_days,
        image: cover_image
      }
    });
    setIsLoading(false);
  };

  const onChange = (key, value) => {
    setLocation({
      ...location,
      [key]: value
    })
  };

  const [cover_image, setUpload] = React.useState(null);
  const [preivew_image, setPreview] = React.useState(null);
  const [isLatLongLoading, setIsLatLongLoading] = React.useState(false);
  const previewImage = file => {
    setPreview(URL.createObjectURL(file));
  };



  const detectLatLong = () => {
    setIsLatLongLoading(true);
    console.log('location', location);
    let address = `${location.addr} ${location.city} ${location.state} ${location.country} ${location.pincode}`;
    axios.post(`${getServerUrl()}/cardware_lat_long`, {
      address
    })
      .then((response) => {
        if (response && response.data && response.data.status) {
          onChange('latitude', response.data.data.lat);
          onChange('longitude', response.data.data.lng);
        } else {
          onChange('latitude', 'Error!');
          onChange('longitude', 'Error!');
        }
        setIsLatLongLoading(false);
      })
      .catch(() => {
        onChange('latitude', 'Error!');
        onChange('longitude', 'Error!');
        setIsLatLongLoading(false);
      })
  };

  return <>
    <Cell overrides={cellPaddingOverrides} span={12}>
      <div style={{ position: 'relative', float: 'right' }}>
        <Button isLoading={isLoading} onClick={onSave} style={{ marginLeft: '16px' }} isSelected>Save</Button>
      </div>
    </Cell>
    <div style={{ padding: '24px', display: 'flex', flexWrap: 'wrap' }}>
      {OPTIONS.map((op, i) => {
        return <div style={{ width: '50%', paddingLeft: '24px' }}>
          <LabelSmall
            style={{
              marginTop: '16px',
              marginBottom: '8px',
            }}
          >
            {op.display}
          </LabelSmall>
          <Input
            value={location[op.key]}
            onChange={(e) => {
              onChange(op.key, e.target.value);
            }}
          />
        </div>
      })}
      <div style={{ width: '50%', paddingLeft: '24px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
        <WorkingDaysModal working_days={location.working_days} onChange={onChange} />
        <div style={{ marginLeft: '18px' }}>
          <Button isSelected isLoading={isLatLongLoading} onClick={() => {
            detectLatLong();
          }}>Detect Lan Long</Button>
        </div>
      </div>
      <div style={{ width: '50%', paddingLeft: '24px' }}>
        <div style={{ padding: '12px', backgroundColor: 'white', marginTop: '18px' }}>
          {/* <div style={{ backgroundColor: 'white' }}> */}
          <ImageUploadCXL
            title={'Location Cover Image'}
            titleHelpText={'Select the cover image for location'}
            previewUrls={preivew_image || location.cover_image_url}
            downloadUrls={[location.cover_image_url]}
            isMultiple={false}
            onDrop={(acceptedFiles, rejectedFiles) => {
              setUpload(acceptedFiles[0]);
              previewImage(acceptedFiles[0]);
            }}
            onImageDelete={() => {
              setUpload(null);
              setPreview(null);
            }}
          />
          {/* </div> */}
        </div>
      </div>
    </div>
  </>
};

export default ({ organization }) => {
  const [locations, setLocations] = React.useState([]);
  const [isLoading, setIsLoading] = React.useState(false);

  const [isImportModalOpen, setIsImportModalOpen] = React.useState(false);
  const [importFile, setImportFile] = React.useState(null);
  const [importLoading, setImportLoading] = React.useState(false);

  const fetchLocations = () => client.query({
    query: gql`
      query locations {
        organization_locations_by_org(organization_id: "${organization.id}") {
          id
          name
          description
          organization_id
          created_at
          updated_at
          addr
          state
          city
          country
          pincode
          latitude
          longitude
          phone
          email
          facebook
          twitter
          working_days
          cover_image_url
          home_gym_mapping
        }
      }
    `,
    fetchPolicy: 'network-only'
  }).then((result) => {
    let l = result.data.organization_locations_by_org;
    l = JSON.parse(JSON.stringify(l));
    setLocations(l.sort((a, b) => {
      let t1 = new Date(a.created_at).getTime();
      let t2 = new Date(b.created_at).getTime();
      return t1 - t2;
    }));
  })

  const addNew = async () => {
    setIsLoading(true);
    await client.mutate({
      mutation: gql`
        mutation createOrganizationLocations {
          createOrganizationLocations(
    				name: ""
            description: ""
            organization_id: "${organization.id}"
          ) {
            id
            name
          }
        }
      `
    })
    await fetchLocations();
    setIsLoading(false);
  };

  React.useEffect(() => {
    fetchLocations();
  }, []);

  console.log('inside locations ----- ', locations);
  return <Grid overrides={gridPaddingOverrides}>
    <Cell overrides={cellPaddingOverrides} span={12}>
      <div style={{ position: 'relative', float: 'right', display: 'flex' }}>
        <Button onClick={() => {
          setIsImportModalOpen(true);
        }} isSelected>Import</Button>
        <div style={{ marginLeft: '8px' }}>
          <Button onClick={() => {
            addNew();
          }} isSelected isLoading={isLoading}>Add New</Button>
        </div>
      </div>
    </Cell>
    <div style={{ marginTop: '16px', width: '100%' }}>
      <Grid overrides={gridPaddingOverrides}>
        <Accordion>
          {(locations).map((l, i) => {
            return <Panel overrides={{
              Content: {
                style: ({ $theme }) => ({
                  paddingLeft: '0 !impmortant'
                })
              }
            }} key={i} title={l.name || 'New Location'}>
              <Location location={l} index={i} setLocations={setLocations} />
            </Panel>
          })}
        </Accordion>
      </Grid>
    </div>
    <Modal
      isOpen={isImportModalOpen}
      onClose={() => {
        setIsImportModalOpen(false);
        setImportLoading(false);
        setImportFile(null);
      }}
    >
      <ModalHeader>{'Import from CSV'}</ModalHeader>
      <ModalBody style={{ flex: '1 1 0' }}>
        {importFile && <LabelMedium>File Selected {importFile.name}</LabelMedium>}
        <FileUploader
          onDrop={(acceptedFiles, rejectedFiles) => {
            setImportFile(acceptedFiles[0])

          }}
        />
      </ModalBody>
      <ModalFooter>
        <ModalButton
          isLoading={importLoading}
          disabled={!importFile}
          onClick={() => {
            setImportLoading(true);
            const formData = new FormData();
            formData.append('file', importFile);
            formData.append('organization_id', organization.id);
            axios.post(`${getServerUrl()}/location_import`, formData)
              .then(async (result) => {
                console.log(result);
                await fetchLocations();
                setImportLoading(false);
                setIsImportModalOpen(false);
                setImportFile(null);
              });
          }}
        >
          {'Import'}
        </ModalButton>
      </ModalFooter>
    </Modal>
  </Grid>
}