import * as React from 'react';
import { Grid, Cell } from 'baseui/layout-grid';
import { useStyletron } from 'baseui';
import {
  LabelMedium,
  LabelSmall,
  HeadingXSmall,
  LabelXSmall,
  HeadingXLarge,
  ParagraphSmall,
} from 'baseui/typography';
import { Skeleton } from 'baseui/skeleton'
import axios from 'axios';
import { getToken } from '../../auth/index';
import { client, getServerUrl } from '../../apollo/client';
import gql from 'graphql-tag';
import { gridPaddingOverrides, cellPaddingOverrides, gridPaddingMarginOverrides } from '../overrides';
import moment from 'moment';
import Button from '../styledButton';
import { Button as BaseUiButton, SIZE } from 'baseui/button';
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  ModalButton,
} from 'baseui/modal';
import { Notification } from "baseui/notification";
import { Input } from 'baseui/input';
import * as MD from 'react-icons/md';
import InfiniteProgressBar from "../admin/infiniteProgressBar";
import { Tabs, Tab } from "baseui/tabs-motion";
import ImageUploadCXL from '../imageUploaderCXL';
import { sourceByType } from '../../util';
import { StatefulPopover, PLACEMENT } from 'baseui/popover';
import { SketchPicker } from 'react-color';
import { Select } from 'baseui/select';
import { updatePwaTemplate } from '../../quries';
import store from '../../redux/store';
import _ from 'lodash';
import PWA_DESIGN_OPTIONS from './pwa-design-options';
import ColorPicker from '../common/ColorPicker';
import styled from '@emotion/styled';
import { createPwaDesignSpecs, pwa_design_specification, updateLoginModuleSpecs, updatePwaDesignSpecs } from './queries';
import { useMutation, useQuery } from '@apollo/client';

const outerGridOverride = {
  marginTop: '30px',
  marginLeft: '0px !important',
  marginRight: '0px !important',
  paddingLeft: '0px !important',
  paddingRight: '0px !important',
};
const InputContainer = styled.div`  
  padding: 24px;
  background: ${props => props.theme.colors.primaryB};
  margin-bottom: 24px;
  display: flex;
  flex-direction: column;
`;

const getSrc = data => sourceByType(data, 'small');

const PWA = ({ organization, defaultTheme }) => {
  const [isError, setIsError] = React.useState(false);
  const [apps, setApps] = React.useState([]);
  const [css, theme] = useStyletron();
  const [name, setName] = React.useState('cardware-web-app-');
  const [nameRequiredNotification, setNameRequiredNotification] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState(false);
  const [isAddNewAppLoading, setIsAddNewAppLoading] = React.useState(false);
  const [isAddNewAppModalOpen, setIsAppNewAppModalOpen] = React.useState(false);
  const [onHover, setOnHover] = React.useState(false);
  const [isEdit, setIsEdit] = React.useState(false);
  const [selectedApp, setSelectedApp] = React.useState(null);
  const [activeKey, setActiveKey] = React.useState("0");

  const [manifest, setManifest] = React.useState({});
  const [preview_icon, set_preview_icon] = React.useState(null);
  const displayOptions = ['minimal-ui', 'standalone', 'minimal-ui', 'browser'];
  const [navigationValues, setNavValues] = React.useState({});
  const [designSpec, setDesignSpec] = React.useState();

  const [branch, setBranch] = React.useState('');

  const { data: designData, loading: designDataLoading, refetch: refetchDesignData } = useQuery(pwa_design_specification(organization.id))
  const [createPwaDesignSpec, { loading: PwaDesignSpecLoader, data: createdPwaDesignSpec }] = useMutation(
    createPwaDesignSpecs
  );
  const [updatePwaDesignSpec, { loading: onUpdateDesignLoader, data: updatedPwaDesignSpec }] = useMutation(
    updatePwaDesignSpecs
  );

  React.useEffect(() => {
    setDesignSpecValues();
  }, [designData]);

  React.useEffect(() => {
    if (!createdPwaDesignSpec || !updatedPwaDesignSpec) return;
    refetchDesignData();
  }, [createdPwaDesignSpec])



  React.useEffect(() => {
    if (!name.includes('cardware-web-app-')) {
      setName('cardware-web-app-');
    }
  }, [name]);

  React.useEffect(() => {
    if (!selectedApp) return;
    let m = {};
    Object.keys(selectedApp.template).forEach(key => {
      if (key != '__typename') {
        m[key] = selectedApp.template[key];
      }
    });
    setDesignSpecValues()
    setManifest(m);
  }, [selectedApp]);

  const setDesignSpecValues = () => {
    const {
      pwa_design_specification
    } = designData || {};
    if (pwa_design_specification && _.isArray(pwa_design_specification) && selectedApp && selectedApp !== null) {
      debugger
      const selectedDesignSpec = pwa_design_specification.find(ele => ele.pwa_app_id === selectedApp.template.pwa_app_id);
      if (selectedDesignSpec) {
        setDesignSpec(selectedDesignSpec);
        let specVals = {};
        PWA_DESIGN_OPTIONS().map(ele => {
          ele.fields.map(ele1 => {
            specVals[ele1.key] = selectedDesignSpec[ele1.key];
          });
        });
        setNavValues(specVals);
      } else {
        setDesignSpec(undefined);
        setNavValues({});
      }
    }

  }

  const setToManifest = (key, value) => {
    let m = { ...manifest };
    m[key] = value;
    console.log(m);
    setManifest(m);
  }

  const fetchDesignSpec = () => {
    client.query({
      query: pwa_design_specification(organization.id),
      fetchPolicy: 'network-only'
    }).then(({ data }) => {

    })
  }

  const fetchApps = () => {
    setIsLoading(true);
    client.query({
      query: gql`
        query apps {
          organization_pwa_app(organization_id: "${organization.id}") {
            app_url
            app_name
            last_build_status
            last_build_id
            heroku_deployment_status
            updated_at
            failure_message
            id
            template {
              id
              background_color
              organization_id
              pwa_app_id
              display
              start_url
              name
              description
              short_name
              icon
              scope
              created_at
              updated_at
            }
          }
        }
      `,
      fetchPolicy: 'network-only'
    }).then(({ data }) => {
      setApps(data.organization_pwa_app);
      setIsLoading(false);
    })
  }

  const addNewApp = async () => {
    if (!name) {
      return setNameRequiredNotification(true);
    }
    if (name.replace('cardware-web-app-', '') == '') {
      return setNameRequiredNotification(true);
    }
    setIsAddNewAppLoading(true);
    const tokens = await getToken();
    axios({
      method: 'post',
      url: `${getServerUrl()}/heroku_deploy_pwa?token=${tokens.idToken}&domain=${organization.auth0.domain}`,
      data: {
        organization_id: organization.id,
        app_name: name
      }
    }).then(responseJson => {
      setIsAddNewAppLoading(false);
      if (responseJson.data.error) {
        alert(responseJson.data.error.message);
        fetchApps();
        return;
      }
      if (responseJson.data.id) {
        fetchApps();
        setIsAppNewAppModalOpen(false);
        return;
      };
      setIsError(true);
    })
      .catch(err => {
        setIsAddNewAppLoading(false);
        console.log(err);
      })
  };

  const pushNewUpdate = async (id) => {
    var status = window.confirm('Are you sure you want to push new update?');
    if (!status) return;
    setIsLoading(true);
    const tokens = await getToken();
    axios({
      method: 'post',
      url: `${getServerUrl()}/heroku_push_update/${id}?token=${tokens.idToken}&domain=${organization.auth0.domain}&branch=${branch}`,
      data: {
        organization_id: organization.id,
      }
    }).then(responseJson => {
      console.log(responseJson.data);
      // if (responseJson.data.last_build_id) {
      fetchApps();
      setIsEdit(false);
      setSelectedApp(null);
      return;
      // };
      // setIsError(true);
    })
      .catch(err => setIsError(true))
  };

  const fetchBuildStatus = async (id) => {
    setIsLoading(true);
    const tokens = await getToken();
    axios({
      method: 'get',
      url: `${getServerUrl()}/heroku_last_build_status/${id}?token=${tokens.idToken}&domain=${organization.auth0.domain}`,
      data: {
        organization_id: organization.id,
      }
    }).then(responseJson => {
      fetchApps();
    })
      .catch(err => setIsError(true))
  }

  const deleteApp = async (id) => {
    var status = window.confirm('Are you sure you want to delete this app?');
    if (!status) return;
    const tokens = await getToken();
    setIsLoading(true);
    axios({
      method: 'delete',
      url: `${getServerUrl()}/heroku_delete_app/${id}?token=${tokens.idToken}&domain=${organization.auth0.domain}`,
      data: {
        organization_id: organization.id,
      }
    }).then(responseJson => {
      fetchApps();
    })
      .catch(err => setIsError(true))
  }

  console.log(manifest);
  const updateManifest = async () => {
    const { background_color, display, scope, start_url, name, short_name, description, icon } = manifest;
    setIsLoading(true);
    const input = {
      background_color,
      display,
      scope,
      start_url,
      name,
      short_name,
      description,
      icon,
      organization_id: manifest.organization_id
    }
    await client.mutate({
      mutation: updatePwaTemplate,
      variables: {
        id: manifest.id,
        input: input,
      },
    }).catch(err => setIsLoading(false));
    setIsEdit(false);
    setSelectedApp(null);
    setIsLoading(false);
    fetchApps();
  }

  const fetchNewAppPushStatus = async (id) => {
    const tokens = await getToken();
    setIsLoading(true);
    axios({
      method: 'get',
      url: `${getServerUrl()}/heroku_new_app_status/${id}?token=${tokens.idToken}&domain=${organization.auth0.domain}`,
      data: {
        organization_id: organization.id,
      }
    }).then(responseJson => {
      fetchApps();
    })
      .catch(err => setIsError(true))
  }

  React.useEffect(() => {
    fetchApps();
  }, []);

  const headerButtonStyle = {
    color: defaultTheme === "dark" ? 'white !important' : 'black !important',
    textTransform: 'capitalize',
  }

  const getDeploymentStatus = (s) => {
    let name = s;
    let color = theme.colors.positive;
    // console.log(s);
    // switch (s) {
    //   case 'pending':
    //     name = 'Pending';
    //     color = theme.colors.contentWarning;
    //     break;
    //   case 'succeeded':
    //     name = 'Success';
    //     color = theme.colors.positive;
    //     break;
    //   default:
    //     name = 'Failed';
    //     color = theme.colors.negative
    // };
    return { name, color }
  }
  console.log(theme)
  const columns = [
    {
      key: 'name',
      display: 'App Name',
      span: 3,
      render: (app) => <LabelMedium
        className={css({
          color: onHover ? 'white !important' : 'black !important',
          textTransform: 'uppercase',
        })}
      >
        {app.app_name}
      </LabelMedium>
    },
    {
      display: 'Deployment Status',
      span: 2,
      render: (app) => <LabelMedium
        className={css({
          color: onHover ? 'white !important' : `${getDeploymentStatus(app.heroku_deployment_status).color} !important`,
          textTransform: 'capitalize',
          fontWeight: 400,
        })}
      >
        {getDeploymentStatus(app.heroku_deployment_status).name}
      </LabelMedium>
    },
    {
      display: 'Last Update Status',
      span: 2,
      render: (app) => {
        if (app.last_build_id) {
          return <LabelMedium
            className={css({
              color: onHover ? 'white !important' : `${getDeploymentStatus(app.last_build_status).color} !important`,
              textTransform: 'capitalize',
              fontWeight: 400,
            })}
          >
            {getDeploymentStatus(app.last_build_status).name}
          </LabelMedium>
        } else {
          return <LabelMedium
            className={css({
              color: onHover ? 'white !important' : `black !important`,
              fontWeight: 400,
              textTransform: 'lowercase',
            })}
          >
            No updates pushed yet
          </LabelMedium>
        }
      }
    },
    {
      display: 'Refresh',
      span: 1,
      render: (app) => {
        const Icon = MD['MdRefresh']
        return <Icon style={{ fontSize: '28px', color: onHover ? 'white' : 'black', cursor: 'pointer' }}
          onClick={() => {
            fetchBuildStatus(app.id);
            if (app.heroku_deployment_status == 'pending') fetchNewAppPushStatus(app.id)
          }}
        />
      }
    },
    {
      display: 'Settings',
      span: 1,
      render: app => <svg
        width="22"
        height="22"
        viewBox="0 0 22 22"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        style={{ cursor: 'pointer' }}
        onClick={() => {
          setSelectedApp(app);
          setIsEdit(true);
        }}
      >
        <path
          d="M19.2022 12.0725C19.243 11.7287 19.2701 11.3713 19.2701 11C19.2701 10.6287 19.243 10.2713 19.1886 9.9275L21.4859 8.1125C21.6898 7.9475 21.7442 7.645 21.6219 7.41125L19.4469 3.6025C19.3109 3.355 19.0255 3.2725 18.7808 3.355L16.0756 4.455C15.5047 4.015 14.9065 3.6575 14.2404 3.3825L13.8326 0.4675C13.7918 0.1925 13.5607 0 13.2889 0H8.93884C8.66696 0 8.44946 0.1925 8.40868 0.4675L8.00086 3.3825C7.33477 3.6575 6.72304 4.02875 6.1657 4.455L3.46053 3.355C3.21584 3.25875 2.93037 3.355 2.79443 3.6025L0.619417 7.41125C0.483478 7.65875 0.537854 7.9475 0.755355 8.1125L3.05271 9.9275C2.99834 10.2713 2.95755 10.6425 2.95755 11C2.95755 11.3575 2.98474 11.7287 3.03912 12.0725L0.741761 13.8875C0.537853 14.0525 0.483478 14.355 0.605823 14.5887L2.78083 18.3975C2.91677 18.645 3.20224 18.7275 3.44693 18.645L6.1521 17.545C6.72304 17.985 7.32117 18.3425 7.98727 18.6175L8.39509 21.5325C8.44946 21.8075 8.66696 22 8.93884 22H13.2889C13.5607 22 13.7918 21.8075 13.819 21.5325L14.2268 18.6175C14.8929 18.3425 15.5047 17.9713 16.062 17.545L18.7672 18.645C19.0119 18.7413 19.2973 18.645 19.4333 18.3975L21.6083 14.5887C21.7442 14.3412 21.6898 14.0525 21.4723 13.8875L19.2022 12.0725ZM11.1139 15.125C8.87087 15.125 7.0357 13.2688 7.0357 11C7.0357 8.73125 8.87087 6.875 11.1139 6.875C13.3568 6.875 15.192 8.73125 15.192 11C15.192 13.2688 13.3568 15.125 11.1139 15.125Z"
          fill={onHover ? 'white' : 'black'}
        />
      </svg>
    },
    {
      display: 'Open App',
      span: 2,
      render: (app) => {
        if (app.app_url) {
          return <div><Button isSelected style={{ marginTop: '0px' }} onClick={
            () => {
              window.open(app.app_url, '_blank');
            }
          }>Open App</Button></div>
        };
        return <></>;
      }
    },
  ];

  const cellOverride = {
    Cell: {
      style: () => ({
        paddingTop: '6px !important',
      }),
    },
  };

  const colorOptions = (
    store.getState().appBasicControls.organization.color_palette || []
  ).map((el, i) => {
    return {
      color: el,
      id: (
        <div
          className={css({
            backgroundColor: `${el}`,
            width: '50px',
            height: '20px',
            borderRadius: '4px',
          })}
        ></div>
      ),
      index: i,
    };
  });

  if (isLoading) {
    return (
      <div style={{ width: '100%', }}>
        <div className={css({ marginLeft: '2rem', marginTop: '1rem' })}>
          {new Array(6).fill('').map(item => {
            return (
              <div className={css({ display: 'flex', flexDirection: 'row', marginTop: '2rem' })}>
                {new Array(1).fill('').map(item => <Skeleton
                  width="100%"
                  height="105px"
                  animation
                  overrides={{
                    Root: {
                      style: {
                        marginRight: '60px'
                      },
                    },
                  }}
                />)}
              </div>
            )
          })}
        </div>
      </div>
    )
  }

  const onUpdateDesign = async () => {
    let valPayload = {};
    Object.keys(navigationValues).map(ele => valPayload[ele] = navigationValues[ele]);
    debugger
    if (designSpec) {
      await updatePwaDesignSpec({
        variables: {
          id: designSpec.id,
          organization_id: organization.id,
          pwa_app_id: selectedApp.template.pwa_app_id,
          ...valPayload
        }
      });
      refetchDesignData();
    } else {
      await createPwaDesignSpec({
        variables: {
          organization_id: organization.id,
          pwa_app_id: selectedApp.template.pwa_app_id,
          ...valPayload
        }
      });
      refetchDesignData();
    }
  }

  //--------------------------------------- Getter ---------------------------------------//

  const getNavigationValue = (key) => {
    const val = _.get(navigationValues, key, undefined);
    return val;
  }

  const onChangeInput = (key, e) => {
    setNavValues({
      ...navigationValues,
      [key]: e.traget.value
    });
  }

  const onChangeColor = (key, color) => {
    setNavValues({
      ...navigationValues,
      [key]: color
    });
  }

  const getOnChangeFunc = (key) => {
    switch (key) {
      case 'Input':
        return onChangeInput;
      case 'color_picker':
        return onChangeColor;
      default:
        return () => { };
        break;
    }
  }

  //--------------------------------------- Render ---------------------------------------//
  const renderFields = (field) => {
    const val = getNavigationValue(field.key);
    const OnChangeFunc = (e) => {
      const func = getOnChangeFunc(field.type);
      func(field.key, e)
    }

    console.log('renderFields value', val)

    switch (field.type) {
      case 'Input':
        return (<>
          <LabelMedium className={css({ marginBottom: '16px', marginTop: '16px' })}>{field.label}</LabelMedium>
          <Input
            value={val}
            onChange={OnChangeFunc}
            placeholder="App name"
            clearOnEscape
          />
        </>);
      case 'color_picker':
        return <>
          <LabelMedium className={css({ marginBottom: '16px', marginTop: '16px' })}>{field.label}</LabelMedium>
          <div style={{ backgroundColor: 'rgb(238, 238, 238)', padding: '16px' }}>
            <ColorPicker color={val} onChange={OnChangeFunc}></ColorPicker>
          </div>
        </>
      default:
        return null;
    }
  }

  const renderDesignOptions = () => (
    <Cell span={12} overrides={cellPaddingOverrides}>
      {
        PWA_DESIGN_OPTIONS().map(ele => {
          return ele.fields.map(renderFields);
        })
      }
      <div
        className={css({
          display: 'flex',
          justifyContent: 'flex-end',
          marginTop: '16px'
        })}
      >
        <BaseUiButton
          onClick={onUpdateDesign}
          isLoading={PwaDesignSpecLoader || onUpdateDesignLoader}
        >
          Update Design
        </BaseUiButton>
      </div>
    </Cell>
  )

  return <>
    <Grid overrides={gridPaddingOverrides}>
      <Cell overrides={cellPaddingOverrides} span={12} align={'right'}>
        <div
          className={css({
            position: 'relative',
            float: 'right',
            marginBottom: '16px',
          })}
        >
          <Button
            isSelected
            isLoading={isAddNewAppLoading}
            disabled={isLoading}
            onClick={() => {
              setIsAppNewAppModalOpen(true)
            }}
            style={{ marginLeft: '16px' }}
          >
            Add new App
          </Button>
        </div>
      </Cell>
      <Cell span={12} overrides={cellPaddingOverrides}>
        {isLoading && <InfiniteProgressBar />}
        <div
          className={css({
            position: 'relative',
            width: '100%',
            height: 'auto',
            background: theme.colors.primaryB,
            marginTop: '0px',
            marginBottom: '16px',
            display: 'flex',
            padding: '16px',
            color: 'white',
            borderRadius: '0px',
          })}
        >
          <Grid overrides={gridPaddingMarginOverrides}>
            {columns.map((col, index) => {
              return <Cell span={col.span} overrides={cellOverride} key={index}>
                <LabelMedium
                  className={css(headerButtonStyle)}
                >
                  {col.display}
                </LabelMedium>
              </Cell>
            })}
          </Grid>
        </div>
        {apps.map((app, index) => {
          return <div
            onMouseEnter={() => false && setOnHover(true)}
            onMouseLeave={() => false && setOnHover(false)}
            className={css({
              position: 'relative',
              width: '100%',
              height: 'auto',
              background: onHover
                ? theme.colors.primaryC
                : theme.colors.channelCardBackgroundColor,
              marginTop: '16px',
              marginBottom: '16px',
              display: 'flex',
              padding: '16px',
              color: onHover ? 'white' : 'black',
              borderRadius: '5px',
              transition: '0.8s',
            })}
          >
            <Grid overrides={{
              Grid: {
                style: () => ({
                  padding: '0px !important',
                  margin: '0px !important',
                  width: '100%',
                  display: 'flex !important',
                  alignItems: 'center !important'
                }),
              },
            }}>
              {columns.map((col, index) => {
                return <Cell span={col.span} overrides={cellOverride} key={index}>
                  <LabelMedium
                    className={css(headerButtonStyle)}
                  >
                    {col.render && col.render(app)}
                  </LabelMedium>
                </Cell>
              })}
            </Grid>
          </div>
        })}
      </Cell>
    </Grid>
    <Modal
      overrides={{
        Backdrop: {
          style: ({ $theme }) => ({
            backdropFilter: $theme.modalBackDropFilter,
          }),
        }
      }}
      isOpen={isAddNewAppModalOpen}
      onClose={() => {
        setIsAppNewAppModalOpen(false);
        setName('');
      }}
      autoFocus={false}
    >
      <ModalHeader>{'Add new pwa app'}</ModalHeader>
      <ModalBody style={{ flex: '1 1 0' }}>
        <Grid
          gridGaps={[12, 12, 24]}
          overrides={{
            Grid: {
              style: ({ $theme }) => outerGridOverride,
            },
          }}
        >
          <Cell
            span={[1, 12]}
            overrides={{
              Cell: {
                style: () => ({
                  paddingLeft: '0px !important',
                }),
              },
            }}
          >
            <LabelMedium style={{ marginBottom: '16px' }}>Title</LabelMedium>
            <Input
              value={name}
              onChange={e => {
                setName(e.target.value);
                nameRequiredNotification && setNameRequiredNotification(false);
              }}
              placeholder="App name"
              clearOnEscape
            />
            {nameRequiredNotification && <Notification kind={"negative"} closeable overrides={{
              Body: {
                style: ({ $theme }) => ({
                  width: '100% !important',
                }),
              }
            }}>{() => "App name is required."}</Notification>}
          </Cell>
        </Grid>
      </ModalBody>
      <ModalFooter>
        <ModalButton
          kind="tertiary"
          onClick={() => setIsAppNewAppModalOpen(false)}
        >
          No
        </ModalButton>
        <ModalButton
          onClick={() => {
            addNewApp();
          }}
          isLoading={isLoading}
          disabled={isLoading}
        >
          Yes
        </ModalButton>
      </ModalFooter>
    </Modal>


    <Modal
      overrides={{
        Dialog: {
          style: {
            width: '70vw',
            display: 'flex',
            flexDirection: 'column',
          },
        },
        Backdrop: {
          style: ({ $theme }) => ({
            backdropFilter: $theme.modalBackDropFilter,
          }),
        },
        Root: {
          style: {
            zIndex: 3,
          }
        },
      }}
      isOpen={selectedApp && isEdit}
      onClose={() => {
        setIsEdit(false);
        setSelectedApp(null);
      }}
      autoFocus={false}
    >
      <ModalHeader>{'Update app'}</ModalHeader>
      <ModalBody style={{ flex: '1 1 0' }}>
        <Tabs
          onChange={({ activeKey }) => {
            setActiveKey(activeKey);
          }}
          activeKey={activeKey}
        >
          <Tab title="Manifest Template">
            <Grid
              gridGaps={[12, 12, 24]}
              overrides={gridPaddingOverrides}
            >
              <Cell span={6} overrides={cellPaddingOverrides}>
                <Cell
                  span={[1, 12]}
                  overrides={{
                    Cell: {
                      style: () => ({
                        paddingLeft: '0px !important',
                      }),
                    },
                  }}
                >
                  <LabelMedium style={{ marginBottom: '16px' }}>Name</LabelMedium>
                  <Input
                    value={manifest.name}
                    onChange={e => {
                      console.log(e.target.value);
                      setToManifest('name', e.target.value)
                    }}
                    clearOnEscape
                  />
                </Cell>
                <Cell
                  span={[1, 12]}
                  overrides={{
                    Cell: {
                      style: () => ({
                        paddingLeft: '0px !important',
                      }),
                    },
                  }}
                >
                  <LabelMedium style={{ marginBottom: '16px' }}>Short Name</LabelMedium>
                  <Input
                    value={manifest.short_name}
                    onChange={e => {
                      setToManifest('short_name', e.target.value.trim())
                    }}
                    clearOnEscape
                  />
                </Cell>
                {/* <Cell
                  span={[1, 12]}
                  overrides={{
                    Cell: {
                      style: () => ({
                        paddingLeft: '0px !important',
                      }),
                    },
                  }}
                >
                  <LabelMedium style={{ marginBottom: '16px' }}>Scope</LabelMedium>
                  <Input
                    value={manifest.scope}
                    onChange={e => {
                      setToManifest('scope', e.target.value)
                    }}
                    clearOnEscape
                  />
                </Cell> */}
                {/* <Cell
                  span={[1, 12]}
                  overrides={{
                    Cell: {
                      style: () => ({
                        paddingLeft: '0px !important',
                      }),
                    },
                  }}
                >
                  <LabelMedium style={{ marginBottom: '16px' }}>Start URL(Automatically sets on new deploy)</LabelMedium>
                  <Input
                    value={manifest.start_url}
                    onChange={e => {
                      setToManifest('start_url', e.target.value)
                    }}
                    clearOnEscape
                  />
                </Cell> */}
                <Cell
                  span={[1, 12]}
                  overrides={{
                    Cell: {
                      style: () => ({
                        paddingLeft: '0px !important',
                      }),
                    },
                  }}
                >
                  <LabelMedium style={{ marginBottom: '16px' }}>Display</LabelMedium>
                  <Select
                    options={displayOptions.map((el) => {
                      return { label: el, id: el }
                    })}
                    value={[{ label: manifest.display, id: manifest.display }]}
                    labelKey="label"
                    valueKey="id"
                    placeholder=''
                    onChange={({ value }) => setToManifest('display', value[0].id)}
                    clearable={false}
                    overrides={{
                      Popover: {
                        props: {
                          overrides: {
                            Body: { style: { zIndex: 3 } },
                          },
                        },
                      },
                    }}
                  />
                </Cell>
              </Cell>
              <Cell span={6} overrides={cellPaddingOverrides}>
                <Cell
                  span={[1, 12]}
                  overrides={{
                    Cell: {
                      style: () => ({
                        paddingLeft: '0px !important',
                      }),
                    },
                  }}
                >
                  <div style={{ height: '82px' }}>
                    <LabelMedium style={{ marginBottom: '16px' }}>Background Color</LabelMedium>
                    <Select
                      options={colorOptions}
                      labelKey="id"
                      valueKey="color"
                      onChange={({ value }) => {
                        if (value.length == 0) return;
                        return setToManifest('background_color', value[0].color);
                      }}
                      value={colorOptions.filter(el => el.color == manifest.background_color)}
                      overrides={{
                        Popover: {
                          props: {
                            overrides: {
                              Body: { style: { zIndex: 3 } },
                            },
                          },
                        },
                      }}
                    />
                  </div>
                </Cell>
                <Cell
                  span={[1, 12]}
                  overrides={{
                    Cell: {
                      style: () => ({
                        paddingLeft: '0px !important',
                      }),
                    },
                  }}
                >
                  <ImageUploadCXL
                    title={'Icon'}
                    titleHelpText={'Select the icon for pwa app'}
                    previewUrls={preview_icon || getSrc(manifest.icon, 'small')}
                    isMultiple={false}
                    onDrop={(acceptedFiles, rejectedFiles) => {
                      setToManifest('icon', acceptedFiles);
                      set_preview_icon(URL.createObjectURL(acceptedFiles[0]));
                    }}
                    onImageDelete={() => {
                      setToManifest('icon', null);
                      set_preview_icon('icon', null);
                    }}
                    showFullBleedSettings={false}
                  />
                </Cell>
              </Cell>
            </Grid>
            <Grid
              gridGaps={[12, 12, 24]}
              overrides={gridPaddingOverrides}
            >
              <hr style={{ height: '2px', margin: 0, marginTop: '16px', marginBottom: '0px', background: '#E2E2E2' }} />
              <div style={{ width: '100%', display: 'flex', alignItems: 'flex-end', flexDirection: 'column' }}>
                <div><BaseUiButton isLoading={isLoading} onClick={() => {
                  updateManifest();
                }}>Update Manifest</BaseUiButton></div>
              </div>
            </Grid>
          </Tab>
          <Tab title="Advanced">
            <Grid
              gridGaps={[12, 12, 24]}
              overrides={gridPaddingOverrides}
            >
              <Cell span={12} overrides={cellPaddingOverrides}>
                <Cell span={5} overrides={cellPaddingOverrides}>
                  <LabelMedium className={css({ marginBottom: '16px', marginTop: '16px' })}>{'Branch'}</LabelMedium>
                  <Input
                    value={branch}
                    onChange={(e) => setBranch(e.target.value)}
                    placeholder="Branch name"
                    clearOnEscape
                  />
                </Cell>
                <Cell span={5} overrides={cellPaddingOverrides}>
                  <BaseUiButton onClick={() => {
                    pushNewUpdate(selectedApp.id)
                  }}>Update App (Push new version)</BaseUiButton>
                </Cell>
              </Cell>
              <hr style={{ height: '2px', margin: 0, marginTop: '16px', marginBottom: '0px', background: '#E2E2E2' }} />
              <Cell span={12} overrides={cellPaddingOverrides}>
                <BaseUiButton onClick={() => {
                  deleteApp(selectedApp.id)
                }}>Delete App</BaseUiButton>
                {/* <ParagraphSmall>this is going to delete app from our portal and heroku as well.</ParagraphSmall> */}
              </Cell>
            </Grid>
          </Tab>
          <Tab title="Design">
            <Grid
              gridGaps={[12, 12, 24]}
              overrides={gridPaddingOverrides}
            >
              {renderDesignOptions()}
            </Grid>
          </Tab>
        </Tabs>
      </ModalBody>
    </Modal>
  </>;
}

export default PWA;