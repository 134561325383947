import gql from 'graphql-tag';
export const apiQuery = () => gql`
  query apis($data: CxlInternalCommonQuery!) {
    cxl_internal_common_query(data: $data) {
      data
      status
    }
  }
`;


export const getData = (api_path_id, parent_record) => gql`
  query data($parent_record: String) {
    get_data_from_api_path(api_path_id: "${api_path_id}" parent_record: $parent_record) {
      data
      status
    }
  }
`;


export const createCommon = gql`
  mutation create($data: CxlInternalCommonQuery!) {
    cxl_internal_common_create(data: $data) {
        data
        status
    }
  }
`;

export const updateCommon = gql`
  mutation update($data: CxlInternalCommonQuery!) {
    cxl_internal_common_update(data: $data) {
        data
        status
    }
  }
`;

export const getCrunchbaseIntegrations = (organization_id) => gql`
query all_crunchbase_integration {
  all_crunchbase_integration_by_org(
      organization_id: "${organization_id}"
    ) {
      id
      title
    }
}
`;