import React from 'react';
import {
  ParagraphSmall,
  ParagraphXSmall,
  LabelSmall
} from 'baseui/typography';
import { Input } from 'baseui/input';
import { Select } from 'baseui/select';
import { Textarea } from 'baseui/textarea';
import KeyValueEditor from '../KeyValueEditor';
import Toggle from 'react-toggle';
import 'react-toggle/style.css';

export const renderToggleField = (field, onChange) => {
  return <div
    style={{
      marginTop: '16px',
      marginBottom: '8px',
    }}>
    {field.hasHr && <hr style={{ height: '2px', margin: 0, marginTop: '22px', background: '#E2E2E2' }} />}
    {field.description && <ParagraphSmall>{field.description}</ParagraphSmall>}
    <div className={'user-toggle-container'} style={{ display: 'flex', flexDirection: 'row', alignContent: 'center' }}>
      <Toggle
        id="cheese-status"
        defaultChecked={field.value}
        icons={false}
        onChange={onChange}
      ></Toggle><p style={{ color: 'black', fontSize: '16px', fontWeight: 500, margin: 0, marginLeft: '0.7rem', marginTop: '3px' }}>{field.display}</p>
    </div>
    {field.limit && <ParagraphSmall>{field.value.length}/{field.limit}</ParagraphSmall>}
    {field.help && <ParagraphXSmall><a target="_blank" href={field.help.url}>{field.help.title}</a></ParagraphXSmall>}
  </div>
}

export const renderSelectField = (field, onChange) => {
  return (
    <div
      style={{
        marginTop: '16px',
        marginBottom: '8px',
      }}>
      {field.hasHr && <hr style={{ height: '2px', margin: 0, marginTop: '22px', background: '#E2E2E2' }} />}
      <LabelSmall
        style={{
          marginTop: '16px',
          marginBottom: '8px',
        }}
      >
        {field.display}
      </LabelSmall>
      {field.description && <ParagraphSmall>{field.description}</ParagraphSmall>}
      <Select
        multi={field.isMultiSelect || false}
        value={field.optionValue}
        options={field.optionValues}
        labelKey={field.labelKey}
        valueKey={field.valueKey}
        onChange={onChange}
        overrides={{
          Dropdown: {
            style: ({ $theme }) => ({
              maxHeight: "70vh"
            })
          }
        }}
        
      />
      {field.limit && <ParagraphSmall>{field.value.length}/{field.limit}</ParagraphSmall>}
      {field.help && <ParagraphXSmall><a target="_blank" href={field.help.url}>{field.help.title}</a></ParagraphXSmall>}
    </div>
  )
}

export const renderKeyValueField = (index, integration, field, onChange) => {
  return (
    <div
      style={{
        marginTop: '16px',
        marginBottom: '8px',
      }}>
      {field.hasHr && <hr style={{ height: '2px', margin: 0, marginTop: '22px', background: '#E2E2E2' }} />}
      <LabelSmall
        style={{
          marginTop: '16px',
          marginBottom: '8px',
        }}
      >
        {field.display}
      </LabelSmall>
      {field.description && <ParagraphSmall>{field.description}</ParagraphSmall>}
      <KeyValueEditor
        fieldIndex={index}
        fieldData={field}
        onChange={onChange}
        value={integration}
      />
      {field.limit && <ParagraphSmall>{integration[field.key].length}/{field.limit}</ParagraphSmall>}
      {field.help && <ParagraphXSmall><a target="_blank" href={field.help.url}>{field.help.title}</a></ParagraphXSmall>}
    </div>
  )
}

export const renderInputField = (field, onChange = () => { }) => {
  return (
    <>
      {field.hasHr && <hr style={{ height: '2px', margin: 0, marginTop: '22px', background: '#E2E2E2' }} />}
      <LabelSmall
        style={{
          marginTop: '16px',
          marginBottom: '8px',
        }}
      >
        {field.display}
      </LabelSmall>
      {field.description && <ParagraphSmall>{field.description}</ParagraphSmall>}
      <Input
        value={field.value || field.initValue || ''}
        onChange={onChange}
        type={field.isPasswordType ? 'password' : 'text'}
        placeholder={field.placeholder}
        clearOnEscape
        disabled={field.disabled}
      />
      {field.limit && <ParagraphSmall>{field.value.length}/{field.limit}</ParagraphSmall>}
      {field.help && <ParagraphXSmall><a target="_blank" href={field.help.url}>{field.help.title}</a></ParagraphXSmall>}
    </>
  )
}

export const renderTextareaField = (field, onChange = () => { }) => {
  return (
    <>
      {field.hasHr && <hr style={{ height: '2px', margin: 0, marginTop: '22px', background: '#E2E2E2' }} />}
      <LabelSmall
        style={{
          marginTop: '16px',
          marginBottom: '8px',
        }}
      >
      {field.display}
      </LabelSmall>
      {field.description && <ParagraphSmall>{field.description}</ParagraphSmall>}
      <Textarea
        value={field.value || field.initValue || ''}
        onChange={onChange}
        placeholder={field.placeholder}
        clearOnEscape
      />
      {field.limit && <ParagraphSmall>{field.value.length}/{field.limit}</ParagraphSmall>}
      {field.help && <ParagraphXSmall><a target="_blank" href={field.help.url}>{field.help.title}</a></ParagraphXSmall>}
    </>
  )
}