import * as React from 'react';
import { Grid, Cell } from 'baseui/layout-grid';
import { gridPaddingOverrides, cellPaddingOverrides } from '../overrides';
import Button from '../styledButton';
import { client, getServerUrl } from '../../apollo/client';
import { Accordion, Panel } from "baseui/accordion";
import gql from 'graphql-tag';
import { Input } from 'baseui/input';
import {
  ParagraphSmall,
  ParagraphXSmall,
  LabelSmall,
  LabelMedium
} from 'baseui/typography';
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  ModalButton,
} from 'baseui/modal';
import { FileUploader } from "baseui/file-uploader";
import ImageUploadCXL from '../imageUploaderCXL';
import axios from 'axios';
import {
  Card,
  StyledBody,
  StyledAction,
  StyledThumbnail,
} from 'baseui/card';
import {
  Checkbox,
  LABEL_PLACEMENT
} from "baseui/checkbox";
import { DatePicker } from "baseui/datepicker";
import moment from "moment";

const outerGridOverride = {
  marginTop: '30px',
  marginLeft: '0px !important',
  marginRight: '0px !important',
  paddingLeft: '0px !important',
  paddingRight: '0px !important',
};

const MemberFields = [
  { key: 'first_name', display: 'First Name', type: 'Input' },
  { key: 'last_name', display: 'Last Name', type: 'Input' },
  { key: 'email', display: 'Email', type: 'Input' },
  { key: 'home_gym', display: 'Home Gym', type: 'Input' },
  { key: 'bar_code', display: 'Bar Code URL', type: 'Input' },
  { key: 'member_id', display: 'Member ID', type: 'Input' },
  { key: 'member_since', display: 'Member Since', type: 'Date' },
  { key: 'member_expires', display: 'Member Expires', type: 'Date' },
  { key: 'is_active', display: 'Is Active?', type: 'Checkbox' },
  { key: 'App_Properties__c', display: 'Channels(Separate multiple using ";" Ex. AA;BB)', type: 'Input' },
  { key: 'App_Primary_Property__c', display: 'Primary Channel', type: 'Input' },
  { key: 'Alumni_Enrollment_Date__c', display: 'Enrollment Date', type: 'Date' },
  { key: 'Redemption_Balance__c', display: 'Points', type: 'Input' },
];


export default (props) => {
  const [isLoading, setIsLoading] = React.useState(false);
  const [members, setMembers] = React.useState([]);

  const [isImportModalOpen, setIsImportModalOpen] = React.useState(false);
  const [importFile, setImportFile] = React.useState(null);
  const [importLoading, setImportLoading] = React.useState(false);
  const [isOpen, setIsOpen] = React.useState(false);
  const [member, setMember] = React.useState({});

  const fetchMembers = () => axios.get('https://cardware-cruchbase-api.herokuapp.com/gg/members')
    .then(result => {
      console.log(result);
      setMembers(result.data);
    })

  const createOrUpdateMember = (member) => {
    setIsLoading(true);
    return axios.post('https://cardware-cruchbase-api.herokuapp.com/gg/members/save', member)
      .then(async (response) => {
        console.log(response);
        await fetchMembers();
        setIsOpen(false);
        setIsLoading(false);
        setMember({
          member_since: moment().format('MM-DD-YYYY'),
          member_expires: moment().format('MM-DD-YYYY'),
          Alumni_Enrollment_Date__c: moment().format('MM-DD-YYYY'),
        });
      })
  };

  React.useEffect(() => {
    fetchMembers();
  }, []);
  console.log(member);

  const getCheckedValue = (v) => {
    if (typeof v === 'string') {
      v = v.toLowerCase();
      return v == 'true' ? true : false;
    };
    return v;
  };

  const getDateValue = (d) => {
    console.log('_____');
    console.log(d);
    if (d) {
      let t = moment(d, 'MM-DD-YYYY').toDate();
      if (t == 'Invalid Date') {
        t = moment(d).toDate();
      }
      if (t == 'Invalid Date') {
        t = new Date();
      };
      console.log(t);
      return [t];
    };
    return [new Date()];
  }

  return (<>
    <Grid overrides={gridPaddingOverrides}>
      <Cell overrides={cellPaddingOverrides} span={12}>
        <div style={{ position: 'relative', float: 'right', display: 'flex' }}>
          <Button onClick={() => {
            setIsImportModalOpen(true);
          }} isSelected>Import</Button>
        </div>
        <div style={{ position: 'relative', float: 'right', display: 'flex', marginRight: '16px' }}>
          <Button onClick={() => {
            setMember({
              member_since: moment().format('MM-DD-YYYY'),
              member_expires: moment().format('MM-DD-YYYY'),
              Alumni_Enrollment_Date__c: moment().format('MM-DD-YYYY'),
            })
            setIsOpen(true);
          }} isSelected>Add New</Button>
        </div>
      </Cell>
      <div style={{ marginTop: '16px', width: '100%', display: 'flex', flexWrap: 'wrap' }}>
        {members.map((member, index) => {
          return <div style={{ marginRight: '16px', marginBottom: '16px', cursor: 'pointer' }} onClick={() => {
            setMember(JSON.parse(JSON.stringify(member)));
            setIsOpen(true);
          }}><Card key={index}
            overrides={{ Root: { style: { width: '400px', borderRadius: '8px' } } }}
            title={`${member.first_name} ${member.last_name}`}
          >
            <StyledThumbnail
              src={member.bar_code}
            />
            <StyledBody>
              <div style={{ marginBottom: '64px', paddingRight: '16px' }}>
                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                  <LabelSmall>Home Gym</LabelSmall>
                  <LabelSmall style={{ marginLeft: '4px' }}>{`${member.home_gym}`}</LabelSmall>
                </div>
                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                  <LabelSmall>Member Since</LabelSmall>
                  <LabelSmall style={{ marginLeft: '4px' }}>{`${member.member_since}`}</LabelSmall>
                </div>
                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                  <LabelSmall>Member Expire</LabelSmall>
                  <LabelSmall style={{ marginLeft: '4px' }}>{`${member.member_expires}`}</LabelSmall>
                </div>
                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                  <LabelSmall>Member ID</LabelSmall>
                  <LabelSmall style={{ marginLeft: '4px' }}>{`${member.member_id}`}</LabelSmall>
                </div>
              </div>
            </StyledBody>
          </Card>
          </div>
        })}
      </div>
    </Grid>
    <Modal
      isOpen={isImportModalOpen}
      onClose={() => {
        setIsImportModalOpen(false);
        setImportLoading(false);
        setImportFile(null);
      }}
    >
      <ModalHeader>{'Import from CSV'}</ModalHeader>
      <ModalBody style={{ flex: '1 1 0' }}>
        {importFile && <LabelMedium>File Selected {importFile.name}</LabelMedium>}
        <FileUploader
          onDrop={(acceptedFiles, rejectedFiles) => {
            setImportFile(acceptedFiles[0])
          }}
        />
      </ModalBody>
      <ModalFooter>
        <ModalButton
          isLoading={importLoading}
          disabled={!importFile}
          onClick={() => {
            setImportLoading(true);
            const formData = new FormData();
            formData.append('file', importFile);
            formData.append('organization_id', props.organization.id);
            formData.append('name_of_collection', 'gg-members');
            formData.append('append_data', 'false');
            axios.post(`https://cardware-cruchbase-api.herokuapp.com/upload_data`, formData)
              .then(async (result) => {
                console.log(result);
                await fetchMembers();
                setImportLoading(false);
                setIsImportModalOpen(false);
                setImportFile(null);
              });
          }}
        >
          {'Import'}
        </ModalButton>
      </ModalFooter>
    </Modal>
    <Modal
      overrides={{
        Backdrop: {
          style: ({ $theme }) => ({
            backdropFilter: $theme.modalBackDropFilter,
          }),
        }
      }}
      isOpen={isOpen}
      onClose={() => {
        setIsOpen(false);
      }}
      autoFocus={false}
    >
      <ModalHeader>{member._id ? 'Update' : 'Add'} Member</ModalHeader>
      <ModalBody style={{ flex: '1 1 0' }}>
        <Grid
          gridGaps={[12, 12, 24]}
          overrides={{
            Grid: {
              style: ({ $theme }) => outerGridOverride,
            },
          }}
        >
          {
            MemberFields.map((field, index) => {
              console.log(member[field.key], field.key)
              switch (field.type) {
                case 'Input':
                  return <Cell
                    span={[1, 12]}
                    overrides={{
                      Cell: {
                        style: () => ({
                          paddingLeft: '0px !important',
                        }),
                      },
                    }}
                    key={index}
                  >
                    <LabelMedium style={{ marginBottom: '16px' }}>{field.display}</LabelMedium>
                    <Input
                      value={member[field.key]}
                      onChange={e => {
                        setMember({
                          ...member, [field.key]: e.target.value
                        });
                      }}
                      clearOnEscape
                    />
                  </Cell>
                case 'Checkbox':
                  return <Cell
                    span={[1, 12]}
                    overrides={{
                      Cell: {
                        style: () => ({
                          paddingLeft: '0px !important',
                        }),
                      },
                    }}
                    key={index}
                  >
                    <LabelMedium style={{ marginBottom: '16px' }}>{field.display}</LabelMedium>
                    <Checkbox
                      checked={getCheckedValue(member[field.key])}
                      onChange={e => setMember({
                        ...member, [field.key]: e.target.checked ? "true": "false"
                      })}
                      labelPlacement={LABEL_PLACEMENT.right}
                    >
                      {field.display}
                    </Checkbox>
                  </Cell>
                case 'Date':
                  return <Cell
                    span={[1, 12]}
                    overrides={{
                      Cell: {
                        style: () => ({
                          paddingLeft: '0px !important',
                        }),
                      },
                    }}
                    key={index}
                  >
                    <LabelMedium style={{ marginBottom: '16px' }}>{field.display}</LabelMedium>
                    <DatePicker
                      value={getDateValue(member[field.key])}
                      onChange={({ date }) =>
                        setMember({
                          ...member, [field.key]: moment(date).format('MM-DD-YYYY')
                        })
                      }
                    />
                  </Cell>
              }
            })
          }
        </Grid>
      </ModalBody>
      <ModalFooter>
        <ModalButton
          onClick={() => {
            createOrUpdateMember(member);
          }}
          isLoading={isLoading}
        >
          {member._id ? 'Update' : 'Add'}
        </ModalButton>
      </ModalFooter>
    </Modal>
  </>);
}